import { callBackend } from './services';


export function getAll (collection){

    return callBackend('GET', `/${collection}`);
  
}

export function getLatest (collection){

    return callBackend('GET', `/${collection}/latest`);
  
}

export function getOne (collection,id){

    return callBackend('GET', `/${collection}/id/${id}`);
  
}
export function getUser (collection,email){

    return callBackend('GET', `/${collection}/email/${email}`);
  
}

export function create (collection,body){

    return callBackend('POST', `/${collection}`,body);
  
}

export function modify (collection,id, body){

    return callBackend('PATCH', `/${collection}/id/${id}`, body);
  
}

export function replace (collection,id, body){

    return callBackend('PUT', `/${collection}/id/${id}`, body);
  
}

export function deleteOne (collection,id){

    return callBackend('DELETE', `/${collection}/id/${id}`);
  
}

export function resetPage (collection){
    return callBackend('GET', `/admin/reset?collection=${collection}`);
}

export function publishCollection (collection){
    
    return callBackend('GET', `/admin/publish?collection=${collection}`);
}


//Files

export function getFile (ui,path,name){
    let sub = process.env.NODE_ENV;
    
    let encodedpath = encodeURIComponent(`${ui}_${sub}/${path}`);
    
    return callBackend('GET', `/file/path/${encodedpath}/name/${name}`);
  
}

//TODO: make this like the others
export function copyImage (filename,source,path,destination){
    let sub = process.env.NODE_ENV;
   
    let encodedSrc = encodeURIComponent(`${source}_${sub}/${path}`);
    let encodedDest = encodeURIComponent(`${destination}_${sub}/${path}`);
   
    return callBackend('POST', `/admin/file/copy/filename/${filename}/source/${encodedSrc}/destination/${encodedDest}`);
  
}

export function uploadFile (ui,path,name,body){
    let sub = process.env.NODE_ENV;
    let encodedpath = encodeURIComponent(`${ui}_${sub}/${path}`);
    return callBackend('POST', `/file/path/${encodedpath}/name/${name}`,body);
  
}

export function deleteFile (ui,path,name){
    let sub = process.env.NODE_ENV;
    let encodedpath = encodeURIComponent(`${ui}_${sub}/${path}`);
   
    return callBackend('DELETE', `/file/path/${encodedpath}/name/${name}`);
  
}