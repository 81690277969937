import { Check } from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Fade,
    FormControl,
    FormControlLabel, FormHelperText,
    Grid,
    InputLabel,
    Modal,
    Select,
    TextField,
    ThemeProvider, Tooltip,
    Typography
} from "@mui/material";
import { useEffect, useState } from 'react';
import { create, getAll } from "../../api/api";
import { theme } from "./Prayer.theme";

export function NewPrayer({npState}) {
    const [isLoading,setisLoading] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const [isError, setisError] = useState(false);
    const {openNP, setOpenNP,setPrayerList,filterPrayers,matches} = npState;
    
    const handleCloseNP = () => {
        setOpenNP(false);
    }
   
    const handleSubmit = (event) => {
        setisLoading(true);
        event.preventDefault();
        if(Object.keys(error).length == 0){
            let body = {'emailMe':emailMe, 'likes':inputs.likes};
            if(inputs.name != "") {
                 body["name"]=inputs.name;
            }
            if(inputs.email != "") {
                body["email"]=inputs.email;
               }
           if(inputs.phone != "") {
            body["phone"]=inputs.phone;
               }
            if(inputs.request != "") {
                body["request"]=inputs.request;
            }
            if(inputs.share != "") {
                body["share"]=inputs.share;
            }
            create('prayer',body).then(data=>{
                if(data.error){
                    console.error('Error Submitting Prayer: ',data.error);   
                    setTimeout(()=>{
                        setisLoading(false);
                        setisError(true);
                    },"1000");
                    
                    setTimeout(() =>{
                        setisError(false);
                        handleCloseNP();
                    }, "3000");
                    //Todo: trigger error message to pop up.
                }else{
                    setTimeout(()=>{
                        setisLoading(false);
                        setisSuccess(true);
                    },"1000");
                    setTimeout(() =>{
                        setisSuccess(false);
                        handleCloseNP();
                        getAll('prayer').then(data=>{
                            setPrayerList(filterPrayers(data.data));
                            
                                });
                    }, "2000");
                }
            });
        }
        else{
            console.debug('This is not valid');
        } 
    }
    const [disable, setDisable] = useState(true);
    const [emailMe, setEmailMe] = useState(false);
    const handleCheck = () =>{
        setEmailMe(!emailMe)
    }
    const [error, setError] = useState({});

    const validateValues = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        switch(name){
            case 'name':
                if(value == ""){
                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "This field is required";
                        return copyInputs;
                    });
                }
                else{
            
                    setError(previous => {
                        const {[name]:property, ...rest} = previous;
                        return rest;
                    });
                }
                break;
            case 'email':
                if(value == ""){
                    // setError({email:"This field is required"});
                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "This field is required";
                        return copyInputs;
                    });
                }
                else if(!(value.includes('@') && value.includes('.'))){
                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "Please enter a valid email address. For example janedoe@domain.com";
                        return copyInputs;
                    });
                    // setError({email:"Please enter a valid email address. For example janedoe@domain.com"});
                }else{
                    setError(previous => {
                        const {[name]:property, ...rest} = previous;
                        return rest;
                    });
                }
                break;
            case 'phone':
                
                if(value == "" || (!isNaN(+value) && value.length <= 10)){
                    setError(previous => {
                        const {[name]:property, ...rest} = previous;
                        return rest;
                    });
                   
                }else{
                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "Please enter a valid phone number.";
                        return copyInputs;
                    });
                }
                break;
            case 'request':
                if(value == ""){
                    // setError({request:"This field is required"});
                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "This field is required.";
                        return copyInputs;
                    });
                }
                else{
                    setError(previous => {
                        const {[name]:property, ...rest} = previous;
                        return rest;
                    });
                }
                break;
            case 'share':
                if(value == ""){

                    setError(previous => {
                        let copyInputs = Object.assign({}, previous);
                        copyInputs[name] = "This field is required.";
                        return copyInputs;
                    });
                }
                else{
                    setError(previous => {
                        const {[name]:property, ...rest} = previous;
                        return rest;
                    });
                }
                break;

        }
    }   
    const validateForm = () =>{
        let x = inputs;
        if((x.name && x.email && x.request && x.share) && (Object.keys(error).length <= 0) ){
            setDisable(false);
        }else{
            setDisable(true);
        }
    }
    
    //Update Inputs of the form
    const [inputs,setInputs] = useState({name:"",email:"",phone:"",request:"",share:"",emailMe:emailMe, likes:0});

    const handleInputChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        setInputs(previous => {
            let copyInputs = Object.assign({}, previous);
            copyInputs[name] = value;
            return copyInputs;
        });
    }

    const handleSelect = (event) => {
        handleInputChange(event);
        validateValues(event);
    }

    //When you click outside of an input then validate input
    const handleBlur = (event) =>{
        validateValues(event);
    }

    useEffect(() => {
        validateForm();
    }, [error]);

    return(
        <ThemeProvider theme={theme}>
            <Modal 
                open={openNP}
                onClose={handleCloseNP}
                sx={{
                    '& .MuiModal-backdrop': {
                        backgroundColor:  'rgba(0, 0, 0, 0.5)'
                    },
                }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openNP}
                    mountOnEnter
                    // style={{ transformOrigin: '0 0 0' }}
                    {...(openNP ? { timeout: 500 } : {})}
                >
                    <form onSubmit={handleSubmit}>
                        <Box 
                            sx={matches?
                                {
                                    position:'absolute',
                                height:'fit-content',
                                overflow:'scroll',
                                boxShadow:8,
                                backgroundColor:'white',
                                width: '80%',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                padding:'10%',
                                    
                                }
                                :{
                            position:'absolute',
                            height:'75vh',
                            overflow:'scroll',
                            boxShadow:8,
                            backgroundColor:'white',
                            width: '30vw',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding:'2rem',
                            }}
                        >
                            {isSuccess?
                            <Alert icon={<Check fontSize="inherit" />} severity="success">
                                We will be praying for you!
                            </Alert>
                            :
                            <></>
                            }  
                                
                            <Backdrop
                                sx={{ color: '#0000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress variant='loading' />
                            </Backdrop>

                            {isError?
                            <Alert severity="error">There was an issue submitting your prayer request. Please try again.</Alert>
                            :
                            <></> 
                            }    

                            <Typography variant='prayer' >Request Prayer</Typography>

                            <Grid 
                                style={{display:'flex', flexdirection:'row' , width:'100%',justifyContent:'space-between',
                                marginTop:'1.5rem'}}>
                                <TextField
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    error={error.name}
                                    helperText={error.name}
                                    required
                                    name='name'
                                    style={{width:'48%'}}
                                    id="component-outlined"
                                    label="Name"
                                    value={inputs.name}
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    name='email'
                                    style={{width:'48%'}}
                                    required
                                    id="component-outlined"
                                    error={error.email}
                                    helperText={error.email}
                                    label="Email"
                                />
                            </Grid>
                            <Grid display={"flex"} flexDirection={"row"} style={{ width:'100%',justifyContent:'center', marginTop:'1.5rem'}}>
                                <TextField
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    name='phone'
                                    style={{width:'100%'}}
                                    id="component-outlined"
                                    // defaultValue="Enter Phone Number"
                                    label="Phone"
                                    error={error.phone}
                                    helperText={error.phone}
                                />
                            </Grid>
                            <Grid display={"flex"} flexDirection={"row"} style={{ width:'100%',justifyContent:'center', marginTop:'1.5rem'}}>
                                <TextField
                                    onBlur={handleBlur}
                                    onChange={handleInputChange}
                                    name='request'
                                    required
                                    id="outlined-multiline-static"
                                    label="Prayer Request"
                                    error={error.request}
                                    helperText={error.request}
                                    multiline
                                    rows={10}
                                    defaultValue={""}
                                    style={{width:'100%'}}
                                />
                            </Grid>
                            <Grid variant='sharecontainer'>
                                <FormControl error={error.share} onBlur={handleBlur} required sx={{ m: 1, minWidth: 120, width:'45%',}}>
                                    <InputLabel id="demo-simple-select-helper-label">Share</InputLabel>
                                    <Select
                                        native
                                        name='share'
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={inputs.share}
                                        label="Share"
                                        onChange={handleSelect}
                                    >
                                        <option value=""> 
                                        <></>
                                        </option> 
                                        <option value={'yes'}>Share</option>
                                        <option value={'no'}>Do Not Share</option>
                                        <option value={'anonymous'}>Share Anonomously</option>
                                    </Select>
                                    <FormHelperText>{error.share}</FormHelperText>
                                </FormControl>
                                <FormControl sx={{}}>
                                    <Tooltip title="Notify me when someone prays for me">
                                        <FormControlLabel variant='email' 
                                            control={
                                                <Checkbox  
                                                    name='emailMe' 
                                                    onChange={handleCheck} 
                                                    value={emailMe} 
                                                    checked={emailMe} 
                                                />}  
                                            label="Email Me"  
                                            labelPlacement="end" 
                                        >
                                        </FormControlLabel>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                            <Grid 
                                display={'flex'} 
                                flexDirection={'row'} 
                                style={{ marginTop:'1.5rem'}}
                            >
                                <Button disabled={disable} type="submit">Submit</Button>
                                <Button onClick={handleCloseNP}>Close</Button>
                            </Grid>
                        </Box>
                    </form>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
}