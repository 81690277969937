
import { ReCaptchaV3Provider, getToken, initializeAppCheck } from "firebase/app-check";
import Cookies from 'universal-cookie';
const {app} = require('./fb');

export const cookies = new Cookies();

export async function getFBToken ()  {
// Create token on client side
    const key = process.env.REACT_APP_RECAPTCHA_KEY;
   
    const appCheck = initializeAppCheck(app(),{provider: new ReCaptchaV3Provider(key),
        isTokenAutoRefreshEnabled: true});
    
    return await getToken(appCheck).then(data =>{
        sessionStorage.setItem("token", data.token);
        return {status:202};
    }).catch(err=>{
        console.error('Error Obtaining Token -',err);
        return {status:500, error:err};

    });
}
