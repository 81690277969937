
import {
    Button,
    Fade,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import { LoadingDisplay } from '../../reusables/LoadingDisplay';

const ErrorDisplay = () => {
  return(
    <Grid  variant='herogridloading'> 
      <p>Something went wrong. Please try again later.</p>
    </Grid> 
  )
}

export function HomeHero({matches,heroImage,status}){
    
    return (
        <Grid key='hero' variant={matches?'smallherogrid':'herogrid'} >
            {
            (status=="pending")?
            <LoadingDisplay />  
            :
            (status=="error")?
              <ErrorDisplay/>
            :
      
              <img style={matches?{
                width:'auto',
                position:'relative',
                overflowX:'hidden',
                height:'70vh',
                opacity:'.4',
              
              }:{
                width:'100%',
                position:'relative',
                height:'100%',
                opacity:'.4',
              }}  src={heroImage}></img>}
              <Fade in={status=="success"}
                  mountOnEnter
                  {...(status=="success" ? { timeout: 1000 } : {})}
                >
                    <Grid 
                  variant='herocontent'
                >
                  <Typography variant={'welcome'} >
                    Welcome to
                  </Typography>
                  <Typography variant='wog' size={'bold'} >
                    White Oak Grove
                  </Typography>
                  <Typography variant='wog' size={'subtext'} >
                    Missionary Baptist Church!
                  </Typography>
                  <Button variant='contained' color='primary' href="https://www.youtube.com/@whiteoakgrovembc8556/streams" target="_blank">Watch Live</Button>
                </Grid>
              </Fade>

          
        </Grid> 
    )
}