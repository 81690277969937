import { CircularProgress, Collapse, Grid, ImageList, ImageListItem, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAll } from '../../api/api';
import { theme as hometheme } from '../Home/Home.theme';
import { theme } from './About.theme';


export function AboutWho() {
    const [isLoading, setisLoading] = useState(true);
    const [about,setAbout] = useState([]); 
    const [overlay,setOverlay] = useState(false);
    useEffect(()=>{
        getAll('about/who').then(data=>{
            setAbout(data.data);
            setisLoading(false);
        });
    },[]);
   
  
    const handleHover = (e) =>{
        let id = e.target.id;
        setOverlay(id);
    }
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <ThemeProvider theme={hometheme}>
            {(isLoading) ? 
              <Grid display="flex" flexDirection={"column"} alignItems={"center"} minWidth={"100%"} minHeight={"100%"} justifyContent={"center"}>
                  <CircularProgress style={{color:hometheme.palette.primary}} />
              </Grid>
                :
              <Grid 
                style={{
                  display:'flex',
                  width:'100%',
                  // border:'4px dashed red',
                  justifyContent:'center'
                }}
              >
                  <Grid  variant="aboutContainer">
                    <Typography variant='h2' 
                    style={{
                      width:'100%',

                    }}>Leadership Team</Typography>
                    <br/>
                    <ImageList 
                      cols={matches?
                        1
                        :
                        (about.length>=3 && (about.length % 3)==0)?
                        3: 
                        (about.length>3)?
                        4
                        :about.length
                        }
                      gap={'35px'}
                       >
                      
                      {about.map((item,index) => (
                        <ImageListItem 
                          style={{
                            // width:'fit-content',
                            // border:'2px dashed red'
                          }}
                          id={item.image}
                          key={item.image}>
                         
                          <img

                            id={item.image}
                            onMouseEnter={(e)=>handleHover(e)}
                            // onMouseLeave={()=>setOverlay(false)}
                              srcSet={`${item.image}`}
                            //   ?w=248&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item.image}`}
                            //   ?w=248&fit=crop&auto=format`}
                              alt={item.title}
                              loading="lazy"
                              style={matches?{}:{height:'23vw',width:'15vw'}}
                          />
                            <Collapse in={overlay != item.image}>
                            {/* <ImageListItemBar
                              // sx={{display:(item.image === overlay)?'none':""}}
                              title={item.title}
                            //   subtitle={item.subtitle}
                              
                            /> */}
                            </Collapse>
                            <Grid
                                id={item.image}
                                key="overlay"
                                style={{position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(0,0,0,0.7)',
                                overflow: 'hidden',
                                // width: '100%',
                                height:(item.image === overlay)?'100%':'14%',
                                transition: '.5s ease',
                                alignContent:'center',
                                textAlign:'center',
                                color:'white',
                                // border:'2px dashed blue',
                
                              }}
                                onMouseLeave={()=>setOverlay(false)}
                            >
                              {(item.image === overlay)&&
                                <Typography variant='h5' style={{
                                // border:'2px dashed red',
                                transition: '.5s ease', fontWeight:'bold'}}>
                                {item.title}
                                </Typography>}
                                <Typography
                                  variant='h6'
                                  style={{
                                      transition: '.5s ease'
                                  }}
                                >
                                    {item.subtitle}
                              
                                </Typography>
                                
                              {/* <Fade in={(item.image === overlay)} appear={false} >
                                {item.title}
                              </Fade> */}
                              {/* {(item.image === overlay)?"":`${item.subtitle}`} */}
                              
                              {/* <Fade in={(item.image != overlay)} appear={false}>
                              {item.subtitle}
                              </Fade> */}
                            </Grid> 
                        </ImageListItem>
                      ))}
                    </ImageList>   
                </Grid>
            </Grid>
            }
        </ThemeProvider>
    );
}