
import { useQuery } from '@tanstack/react-query';
import { getAll, getFile, getLatest } from '../../api/api';

export function getData(collection) {
  return getAll(collection).then(res=> {
      let dataArr = res.data;
      return dataArr;
    });
}

export function useLoadData({collection}){
    const {status,error, data} = useQuery({
        queryKey: [collection],
        queryFn:()=> getData(collection),
      });
    return [status,error,data];
}

function loadFile({ui,path,filename, key}){
   return getFile(ui,path,filename).then(data => {
      if(!data.error){
        return data.data.url;
      }else{
        return "";
      }
    }).catch((e)=>{
      console.error('Error: useLoadFile: ',e);
    });
}

export function useLoadFile({ui,path,filename,key}){
  const {status,error, data} = useQuery({
    enabled: ![null,undefined].includes(filename),
    queryKey: [key],
    queryFn:()=> loadFile({ui:ui,path:path,filename:filename,key:key}),
  });
  return [status,error,data];
}

export function getLatestEventData(collection) {
  return getLatest(collection).then(res=> {
    let data = res.data;
    if(data.length>0){
      return res.data[0]; 
    }else{
      return null;
      // {title:
      //   "Wednesday Night Bible Study",
      //   image:"https://firebasestorage.googleapis.com/v0/b/wog-mbc.appspot.com/o/images%2Fbiblestudy.jpeg?alt=media&token=6baeee56-4bf5-4993-ad6c-85566ed46278"
      // };
    }
  });
}

export function useLoadLatestEventData({collection}){
  const {status,error, data} = useQuery({
      queryKey: [collection],
      queryFn:()=> getLatestEventData(collection),
    });
    return [status,error,data];
  }