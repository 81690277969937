import "@fontsource/league-spartan";
import "@fontsource/league-spartan/100.css";
import "@fontsource/league-spartan/700.css"
import "@fontsource/league-spartan/600.css"
import "@fontsource/league-spartan/900.css"

import design from '../assets/entiredesign.png';
// import { Typography } from "@mui/material";
// import styled from 'styled-components';
import { createTheme, responsiveFontSizes,useTheme } from '@mui/material/styles';

let theme = createTheme(
  {
    palette: {
      primary: {
        // light: '#757ce8',
        main: '#661414',
        // dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        // light: '#757ce8',
        main: '#462234',
        // dark: '#002884',
        contrastText: '#fff',
      },
      info:{
        // light: '#757ce8',
        main: '#E5BD21',
        // dark: '#002884',
        contrastText: '#462234',
      }
    },
    typography:{
      fontFamily: [
        "League Spartan",
        'Puzzled'
      ],
      button:{
        textTransform: 'uppercase',
        fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      
      }, 
      h1:{
        fontSize:'4.4rem',
      },
      // h2:{
      //   fontSize:'2.2rem',
      // },
      // h3:{
      //   fontSize:'1.2rem',
    
      // }
    
    },
    
  
  }

);

theme.typography = {
  fontFamily: [
    "League Spartan",
    'Puzzled'
  ],
  button:{
    textTransform: 'uppercase',
    fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily:'League Spartan'
  }, 
  // welcome:{
  //   fontWeight:'lighter',
  //   fontFamily:'Puzzled',
  //   fontSize:'4.4rem',

  //   color: '#462234',
  // }
  h1:{
    fontSize:'3rem',
    fontWeight:'bold',

  },
  h2:{
    fontSize:'2.2rem',
    fontWeight:'bold',
  },
  h3:{
    fontSize:'1.5rem',
    fontWeight:'bold',
  
  }

};

// theme = {
//     colors: {
//       primary: '#661414',//burgundy -rgba(102, 20, 20, 1)
//       bg: '#F8F4E4',//cream -rgba(248, 244, 228, 1)
//       text: 'white', //rgba(255, 255, 255, 1)
//       title: '#462234',//dark purple - rgba(70, 34, 52, 1)
//       body: 'black', //rgba(0, 0, 0, 1)
//       button: '#E5BD21',//yellow -rgba(229, 189, 33, 1)
//       highlight: '#E5BD21', //yellow
//       grey: '#a9958e'
//     },
//     fontSize: {
//       small: '.8rem',
//       medium: '1rem',
//       large: '1.5rem',
//       xlarge:'2.5rem',
//       xxLarge:"5rem",
//       [theme.breakpoints.down('md')]:
//       {
//         large:'1rem',
//         xlarge:'1.5rem',
//         xxLarge:"2.5rem",
//       },
//     },
//     font:{
//       text:"League Spartan",
//       script: 'Puzzled'
//     },
//     fontWeight:{
//       normal:'normal',
//       bold:'bold',
//       light: 'lighter',
//       bolder: '900',
//     },
//     textTransform:{
//       uppercase:'uppercase',
//       none:'none'
//     }
//   };
  
   
  //  export const hometheme 
   
   theme = createTheme(theme,{
    components:{
      MuiGrid:{
        variants:[
          {
            props: { variant: 'herogrid'},
            style:{
              display:'flex',
              flex:1,
              flexDirection:'column',
              alignItems: 'center',
              width:'100%',
              height:'100vh',
              background:'black',
              
            }
          },
          {
            props: { variant: 'smallherogrid'},
            style:{
              display:'flex',
   
              flexDirection:'column',
              alignItems: 'center',
              width:'100%',
              height:'100%',
              background:'black',
              overflowX:'hidden'
              
            }
          },
          {
            props: {variant: 'herogridloading'},
            style:{
              display:'flex',
              flexDirection:'column',
              alignItems: 'center',
              justifyContent:'center',
              width:'100%',
              height:'100vh',
              
              // background:'transparent',
              // border:'3px dashed red',
            }
          },
          {
            props: { variant: 'herocontent'},
            style:{
              marginTop:"15vh",
              width:'50vw',
              my:'15',
              alignItems:"center",
              gap:'1',
              minWidth:"fit-content",
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",
              // border: '2px solid grey', 
              top:'20%', minHeight:"fit-content",
              maxHeight:'80vh', 
              position:'absolute'
            }
          },
          {
            props: {variant: 'main'},
            style:{
              backgroundColor:'white',
              backgroundImage:`url(${design})`,
              display:"flex",
              flexDirection:"column",
              justifyContent:"space-evenly",
              // border:'4px dashed orange',
              // padding:'20vh',
              width:'100%',
              backgroundPositionX: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
             alignItems: "center",

            }
          },
          {
            props:{ variant: 'sectionA'},
            style:{
              display:"flex",
              justifyContent:"space-evenly",
              flexDirection:"row",
              // marginTop:'15vh',
              height:"65vh",
              // width:'100%',
                  //  paddingTop: "18%",
              // border:'4px dashed red',
              backgroundSize:'cover',
              paddingInline:'10vw',
              backgroundColor:'rgba(0,0,0,0.7)',
              backgroundBlendMode:'darken',
              //  opacity:'0.9'
            }
          },
          
          {
            props: { variant: 'sectionAcontent'},
            style:{
              // border:'4px dashed red',
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              
              justifyContent:'center',
              // flex:'1',
              // backgroundSize:'cover',
              // backgroundBlendMode:'darken',
              // backgroundColor:'rgba(0,0,0,0.5)',
              width:'25%',

            }
          },
          {
            props:{ variant: 'sectionB'},
            style:{
              display:"flex", 
              flexDirection:"column",
               alignItems:"center",
               minHeight:"fit-content",
               marginTop:"10vh",
                // paddingBlock:'10vh', 
              // marginBottom:'10vh',
              //  backgroundColor: theme.colors.title
               
              //  border:'4px dashed blue',
            }
          },
          {
            props:{variant:'sectionContent'},
            style:{
              display:'flex',
              flexDirection:'column',
              width:'fit-content',
              // border:'2px dashed blue',
            
            }
          },
          // {
          //   props:{variant: 'sectionB'},
          //   style:{
          //     display:"flex", 
          //     flexDirection:"column",
          //      alignItems:"center",
          //      minHeight:"fit-content",
          //      marginTop:"10vh",
          //      width:'75%',

          //     //  border:`6px dashed ${theme.palette.primary}`,
          //     // backgroundColor: 'rgba(102, 20, 20, 0.5)',//theme.colors.title,
          //     //  backgroundColor: 'rgba(248, 244, 228, 1)',
          //     //  paddingInline:'15vh',
          //      paddingBlock:'5vh',

               
          //     //  border:'4px dashed blue',
          //   }
          // },
          {
            props:{variant:'eventImg'},
            style:{
              display:"flex",
              flex:1, 
              justifyContent:'center',
              // border:`1px solid ${theme.palette.primary}`,
              // maxWidth:'50%'
            }
          },
          {
            props: { variant: 'eventsection'},
            style:{
              display:"flex",
              flexDirection:"row",
              width:"100%",
              marginTop:'8vh',
              // border:'4px dashed red',

            }
          },
          {
            props: { variant: 'eventtext'},
            style:{
              display:"flex", 
              flexDirection:'column', 
              flex:1,
              alignItems:"center",
              paddingBlock:'5vw',
              marginLeft:'2vw',
              // paddingLeft:'5vw',
              // paddingRight:'5vw',
              // border:'4px dashed red',
            }
          },
          {
            props: { variant: 'pastorsection'},
            style:{
              display:"flex",
              flexDirection:"row",
              width:"100%",
              // border:'4px dashed red',
              alignItems:'center',
              justifyContent:'space-evenly',
              paddingInline:'5vw',
              marginTop:"15vh",
              // marginTop:'5vh'

            }
          },
          {
            props: { variant: 'smallpastorsection'},
            style:{
              display:"flex",
              flexDirection:"column",
              width:"100%",
              // border:'4px dashed red',
              alignItems:'center',
              justifyContent:'space-evenly',
              paddingInline:'5vw',
              marginTop:"15vh",
              // marginTop:'5vh'

            }
          },
         
          {
            props: { variant: 'pastorText'},
            style:{
              display:"flex", 
              flexDirection:'column', 
              flex:1,
              alignItems:"center",
              maxWidth:'40%',
              // paddingInline:'5vw',
              // paddingLeft:'5vw',
              // paddingRight:'5vw',
            }
          },
          
          {
            props:{ variant: 'sectionC'},
            style:{
              position:'relative',
              display:"flex", 
              flexDirection:"column",
              alignItems:"center",
              width:'100%',
              // minHeight:"fit-content",
              marginTop:"15vh",
              marginBottom: '15vh',
              // paddingBlock:'10vh',
              height:'80vh',
              // marginBottom:'10vh',
              justifyContent:'center',
               backgroundColor: 'black',
               
              //  border:'4px dashed blue',
            }
          },
          {
            props:{ variant: 'smallsectionC'},
            style:{
              position:'relative',
              display:"flex", 
              flexDirection:"column",
              alignItems:"center",
              width:'100%',
              // minHeight:"fit-content",
              marginTop:"10vh",
              marginBottom: '10vh',
              // paddingBlock:'10vh',
              height:'30vh',
              // marginBottom:'10vh',
              justifyContent:'center',
               backgroundColor: 'black',
               
              //  border:'4px dashed blue',
            }
          },
        ]

      },
      MuiCard:{
        components:{
          variants:[
            { 
              props:{ variant:'sermoncontainer'},

              style:{
                width:"80vw",
                height:"10vh"
              }
            },
          ]
        }
      },
      MuiCardMedia:{
        components:{
          variants:[
            { 
              props:{ variant:'sermon'},

              style:{
                // width:"100%",
                // height:"100%"
              }
            },
          ]
        }
      },
      MuiButton: {
        styleOverrides :{
          root:({ownerState}) => ({
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
            // fontFamily: theme.font.text,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginTop: "7vh",
            border: "none",
            width: "fit-content",
            height: "fit-content",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            paddingTop: ".8rem",
            paddingBottom: ".8rem",
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
              border:`1px solid ${theme.palette.primary.main}`,
             
             },
            [theme.breakpoints.down('md')]:{
             
              fontSize: '0.8rem'
            }
             
                  
              })  
        },
        variants:[

          // {
          //   props:{variant:'hero'},
          //   style:{
          //     '&:hover': {
          //       backgroundColor: 'transparent',
          //       color: theme.palette.secondary,
          //       border:`1px solid ${theme.palette.secondary}`,
               
          //   },
          //   }
          // },
        
        ]
      },
      MuiTypography:{
        styleOverrides:{
          root:{
            fontFamily: 'League Spartan',
            textTransform: "uppercase",
          }
         
        },
          variants:[
            { 
              props: {variant:'welcome'},
              style:{
                fontWeight: 'lighter',
                fontFamily: 'Puzzled',
                textTransform: "none",
                // textTransform: theme.textTransform.none,
                fontSize: '4.4rem',
                color: "white",
                
              }
            },
            { 
              props: {variant:'smallwelcome'},
              style:{
                fontWeight: 'lighter',
                fontFamily: 'Puzzled',
                textTransform: 'none',
                fontSize: '4.4rem',
                color: "white",
                
              }
            },
           
            {
              props: {variant:'wog'},
              style:{

                color: 'white',
              }
            },
            {
              props: {size:'smallBold'},
              style:{
                fontWeight:'bold',
                fontSize: '2.8rem',
                
              }
            },
            {
              props: {size:'smallsub'},
              style:{
                fontWeight: 'normal',
                fontSize: '1.8rem',
                
              }
            },
            { 
              props: {variant:'wog', size:'bold'},
              style:{
                fontWeight:'bold',
                fontSize: '4.4rem'
              }
            },
            { 
              props: {variant:'wog', size:'subtext'},
              style:{
                fontWeight:'normal',
                fontSize: '2.2rem',
               
              }
            },
            {
              props: { variant: 'sectiontitle'},
              style:{
                color: theme.palette.primary.main,
                fontSize: '2.2rem',
                fontWeight: 'bold'
                // textTransform: theme.textTransform.none,
              
              }
            },
           
            {
              props: { variant: 'title'},
              style:{
                color: theme.palette.secondary.main,
                fontSize: '2.3rem',
                textTransform: 'none',
                // position:'absolute',
                // left:'-7rem',
                // top:'17rem'
              }
            },
          
            {
              props: { variant: 'smalltitle'},
              style:{
                color: 'white',
                fontSize: '1.3rem',

                textTransform: 'none',
                position:'absolute',
                left:'-2rem',
                top:'3rem'
              }
            },
            {
              props: { variant: 'link'},
              style:{
                display:"flex", 
                // paddingTop:"10%", 
                color: 'white' , 
                // fontFamily: theme.font.text, 
                fontWeight: 'bold', 
                fontSize: '1.2rem',
                '&:hover':{
                  color:'rgba(240, 240, 240,0.8)'
                }
              }
            },
            {
              props: {variant:'eventtitle'},
              style:{display:"flex", 
              color: theme.palette.secondary.main , 
              // fontFamily: theme.font.text, 
              fontWeight: 'bold',
              fontSize: '1.2rem',
              

            }
            },
            {
              props: {variant:'pastortitle'},
              style:{display:"flex", 
              color: theme.palette.secondary.main , 
              //  fontFamily: theme.font.text, 
              fontWeight: 'bold', 
              fontSize: '1.2rem',
              // fontStyle:'italic',
              textTransform:'none'
              

            }
            },
            {
              props: { variant: 'eventbody'},
              style:{
                textTransform: 'none',
                fontSize: '1,2rem',

              }
            },
            {
            props: { variant: 'pastorbody'},
            style:{
              textTransform: 'none',
              fontSize: '1,2rem',
    

            }

          },
            {
              props: { color: 'date'},
              style:{
                fontStyle:'italic',
                fontSize: '1rem',

              }
            }

            

          ] 
      },
      MuiSvgIcon:{
        styleOverrides :{
          root:({ownerState}) => ({
            color: theme.palette.primary.main, 
          })
        },
        variants:[
          {
            props: { variant: 'icon'},
            style:{
              display:"flex", 
              color: theme.palette.primary.main, 
              // fontFamily: theme.font.text, 
              // fontWeight: theme.fontWeight.normal, 
              fontSize: '2.2rem'
            
            }
          }
        ]
      },
      MuiIconButton:{
        styleOverrides :{
          root:({ownerState}) => ({
     
          })
        },
        variants:[
          {
            props: { variant: 'solid-background'},
            style:{
              width:'fit-content',
              alignSelf:'center',
              border:'1px solid rgba(230,230,230,1)',
              color: theme.palette.primary.main, 
              backgroundColor: 'rgba(255,255,255,1)',
              boxShadow: '2px 2px 6px rgba(100,100,100,1)',
              '&:hover':{
                backgroundColor:'rgba(180,180,180,0.8)',
                border:'1px solid rgba(180,180,180,0.8)',
              }
            }
          },
        
        ]
      },
      MuiAppBar:{
        variants:[
          {
            props: { variant: 'login'},
            style:{
              // display:"flex", 
              backgroundColor: 'rgb(70, 60, 60)',
              width: '100%',
              height:'15vh',
              // marginLeft:  pathname.includes('/admin/')? `calc(80% -${adminWidth}px)`:"",
              //   marginRight: pathname.includes('/admin')? `276px`:"",
              marginTop: 0,
              // paddingLeft: 50,
              
              //   borderBottom:`${navBorder}`,
              transitionProperty:'background-color, border-bottom',
              transitionTimingFunction:'ease-in-out',
              transitionDuration:'0.4s',
              // alignItems:'center'
              // fontFamily: theme.font.text, 
              // fontWeight: theme.fontWeight.normal, 
              // fontSize: '2.2rem'
            
            }
          }
        ]
      },
      MuiInputBase:{
        styleOverrides:{
          root:{
            fontFamily:"League Spartan",
            '& .MuiInputLabel-root':{
              fontFamily:"League Spartan",
            }
          },
        
        
      }},
      MuiInputLabel:{
        styleOverrides:{
          root:{
            fontFamily:"League Spartan",
          }
        }
      },
      MuiAlert:{
        styleOverrides:{
          root:{
            fontFamily:"League Spartan",
            position:'fixed',
            zIndex:1000,
            top:'50%',
            left:'40%',
            
          }
        }
      },
      MuiLink:{
        defaultProps: {
          
          underline:'hover',
          
        },
        styleOverrides:{
          root:{
            fontFamily:"League Spartan",
            color: 'black',
            marginLeft:20,

            '&.MuiLink-button ':{
              '&:disabled':{
                color:'grey',
                pointerEvents:'none'

              }
            
            }
          }
        }
      },
     
      MuiDrawer:{
        styleOverrides:{
          root:{
            width: 250,
            backgroundColor:'white',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
            width: 250,
            boxSizing: 'border-box',
          

            },
          '& .MuiDrawer-paperAnchorRight':{
            boxShadow:'2px 2px 10px grey',
            // '& .MuiDivider-root':{
            //   backgroundColor:'black'
            // },

          },
          '& .MuiDrawer-paperAnchorLeft':{
            backgroundColor:'rgb(70, 60, 60)',
            color:'white',
            '& .MuiSvgIcon-root':{
              color:'white'
            },
            '& .MuiDivider-root':{
              backgroundColor:'rgba(1,0,0,0.5)'
            },
            '& .MuiTypography-root':{
              
              
            }
          }
        }
        }
      },
      MuiListItemButton:{
        styleOverrides:{
          root:{
            // color: theme.palette.primary.main,
            '&:hover':{
              color:'green'
            },
            '&.Mui-selected':{
              color: theme.palette.primary.main
            }

          },
        },
      },
      MuiMenuItem:{
        styleOverrides:{
          root:{
            fontFamily:'League Spartan',
          }
        }
      },
     
    }});

  
export {theme};