const url = process.env.REACT_APP_BACKEND_URL;
const key = process.env.REACT_APP_KEY;
export async function callBackend (method, path, body=null) {
   try{
      const options = {
         method: method,
         mode:"cors",
         headers: {
            'Accept':  'application/json',
            'Content-Type':(path.includes("/file/"))?  "image/jpeg":'application/json',
           'X-Firebase-AppCheck': sessionStorage.getItem('token'),
         },
       };
      if(body != null){ 
         //Checking to see if the type is a Blob
         //Blob have a structure {size,type}
         if(!body.type)
         {
            options['body']= JSON.stringify(body)
         }else{
            options['body']= body;
         }
      } 
      return fetch(`${url}${path}`,options)
      .then(response => 
         {
            if([200,201].includes(response.status)){
               return response.json();
            }
            else if(response.status == 204){
               return {}
            }
            else{
             return response.json().then(body=>{
               return {'error':body};
              })
            }
         }
        )
      .then(data => {return data})
      .catch(error => {console.error('backend error: ',error); return error;});   
   }
   catch(err) {
      console.error('Error trying to call backend: ',err);
   }
}

export async function youtubeLatest (){
   //Get playlists from channel
   let params = {
      key:key,
      part:'contentDetails',
      playlistId:'UU8dt8rupmDo99BMRoHbsA5A',
      maxResults:1
   }
   //Get a the lastest of videos in the playlist
   const url = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${params.playlistId}&part=${params.part}&maxResults=${params.maxResults}&key=${params.key}`;
   const options = {
      method: 'GET',
      headers:{
         'Accept':'application/json'
      },
      
    };
   return fetch(url,options)
      .then(response => response.json())
      .then((data)=> 
         {
            return data.items[0].contentDetails.videoId;
         }
         )
}



