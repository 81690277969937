import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue,red } from '@mui/material/colors';
import {theme as hometheme } from '../Home/Home.theme';

let theme = createTheme();
theme = responsiveFontSizes(theme);
theme = createTheme(theme,{
    components: {
       
        MuiGrid:{
            variants: [
                {
                    props: {variant:'main'},
                    style:{
                        borderTop:`2px solid ${hometheme.palette.primary.main}`, 
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:'center',
                        alignItems:"center",
                        paddingBlock:'2.5rem',
                        paddingInline:'10rem',
                        marginTop:'10vh',
                        width: '100%',
                        height:'40vh',
                        [theme.breakpoints.down('sm')]: {
                            borderTop:`2px solid ${hometheme.palette.primary.main}`, 
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'3vh',
                            width: '100%',
                            height:'auto',
                            marginTop:'10%',
                        }
                      
                    }
                },
                // {
                //     props: {variant:'smallmain'},
                //     style:{
                //         borderTop:`2px solid ${hometheme.palette.primary.main}`, 
                //         display:"flex",
                //         flexDirection:"column",
                //         justifyContent:'center',
                //         alignItems:"center",
                //         padding:'3vh',
                //         width: '100%',
                //         marginTop:'10%',

                //     }
                // },
                {
                    props: { variant:'containerA'},
                    style:{
                        display:"flex",
                        flexDirection:'column',
                        alignItems:"center",
                        flex:'2',
                        backgroundColor:`${hometheme.palette.primary.main}`,
                        height:"100%", 
                        padding:"2rem",
                        width:'fit-content',
                        maxWidth:'25%',
                        boxShadow:'-10px 10px 0px 0px rgba(229, 189, 33, 1)',
                        // border:'2px dashed orange'   ,
                        [theme.breakpoints.down('sm')]: {
                            maxWidth:'100%',
                            // border:'2px dashed blue'   ,
                            flex:0,
                            height:"100%", 
                            padding:"1rem",
                            width: '100%',
                            // maxWidth:'20%',
                            boxShadow:'-10px 10px 0px 0px rgba(229, 189, 33, 1)'
                             
                        }
                    }
                },
                // {
                //     props: { variant:'smallcontainerA'},
                //     style:{
                //         display:"flex",
                //         alignItems:"center",
                //         flex:1,
                //         backgroundColor:`${hometheme.palette.primary.main}`,
                //         height:"100%", 
                //         padding:"1rem",
                //         width: '100%',
                //         // maxWidth:'20%',
                //         boxShadow:'-10px 10px 0px 0px rgba(229, 189, 33, 1)'
                                              
                //     }
                // },
               
                {
                    props:{variant:'crestgrid'},
                    style:{
                        display:'flex',
                        maxWidth:'10vw',
                        // alignSelf:'flex-start',
                        // justifyContent:'flex-start',
                        flex:'1',
                        [theme.breakpoints.down('sm')]: {
                            maxWidth:'100%',
                        },
                        // border:'2px dashed orange'          
                    }
                },
                {
                    props: { variant:'quote'},
                    style:{
                        display:"flex",
                        flex:'2',
                        alignItems:"center",
                        flexDirection:"row",
                        justifyContent:"center",
                        paddingBlock:'1rem',
                        [theme.breakpoints.down('sm')]: {
                            // flex:'3',
                            // width:'2vw'
                        },
                        // height:"80%", 
                        // padding:'1rem'
                        // border:'2px dashed blue'
                       
                    }
                },
                {
                    props: { variant:'quoteRowLeft'},
                    style:{ 
                        paddingInline:'0.5rem',
                        // border:'2px dashed orange',
                        display:"flex",
                        flexDirection:'column',
                        textAlign:'end',
                        lineHeight:'1.5rem',
                        justifyContent:'center',
                        height:'100%',
                    }
                },
                {
                    props: { variant:'quoteRowRight'},
                    style:{ 
                        paddingInline:'0.5rem',
                        // border:'2px dashed orange',
                        lineHeight:'1.5rem',
                        display:"flex",
                        flexDirection:'column',
                        height:'100%',
                        justifyContent:'center',
                    }
                },
                {
                    props: { variant:'containerB'},
                    style:{
                        display:"flex",
                        flex:'3',
                        alignItems:"self-start",
                        justifyContent:"space-evenly",
                        height:"100%", 
          
                        maxWidth:'50%',
                        // border:'2px dashed orange',
                    }
                },
                {
                    props: { variant:'smallcontainerB'},
                    style:{
                        display:"flex",
                        flexDirection:'column',
                        flex:1,
                        alignItems:"self-start",
                        justifyContent:"space-evenly",
                        height:"100%", 
                        marginTop:'4vh',
                        marginLeft:'2vw',
                        width:'100%',

                        // maxWidth:'50%',
                        // border:'2px dashed red'
                       
                    }
                },
                {
                    props: {variant:'section'},
                    style:{
                        display:"flex",
                        flexDirection:"column",
                        padding:"1rem",
                        height:'80%',
                        // justifyContent:'space-between',
                        // border:'2px dashed red'
                    }
                },
                {
                    props: {variant:'sectionList'},
                    style:{
                        display:"flex",
                        flexDirection:"column",
                        height:'60%',
                        marginTop:'1rem',
                        justifyContent:'space-between',
                        // border:'2px dashed red'
                    }
                },
                {
                    props: {variant:'bottomBar'},
                    style:{
                        // borderTop:`2px dashed ${hometheme.palette.primary.main}`, 
                        backgroundColor:'rgba(0,0,0,1)',
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:'center',
                        alignItems:"center",
                        padding:'1rem',
                        // marginTop:'10vh',
                        width: '100%',
                        [theme.breakpoints.down('md')]:{
                            flexDirection:'column',
                            height:'fit-content'
                        }
                        // height:'10vh',
                        // marginTop:'10%'
                        
                    }
                },
                {
                    props: {variant:'social'},
                    style:{
                        display:"flex",
                        justifyContent:'space-evenly',
                        width:'fit-content',

                        // border:'2px solid red'
                        // marginTop:'1rem',
                        // textAlign:'center',

                    }
                },
                {
                    props: {variant:'smallsocial'},
                    style:{
                        display:"flex",
                        justifyContent:'space-evenly',
                        marginTop:'1rem',
                        alignSelf:'center',
                        // textAlign:'center',

                    }
                },
            ]
        },
        
        MuiTypography:{
            styleOverrides:{
                root:{
                    underline:'none',
                    
                }
            },
            style:{
                fontFamily: 'League Spartan',
                
                
            },
           
            variants:[
                {
                    props: {variant: 'thin'},
                    style:{
                        fontWeight: 'light'
                    }
                },
                {
                    props: {variant: 'normal'},
                    style:{
                        fontWeight: 'normal',
                        marginBottom:"2%"
                    }
                },
                {
                    props: {variant: 'bold'},
                    style:{
                        fontWeight: 'bold',

                    }
                },
                {
                    props: {variant: 'bolder'},
                    style:{
                        fontWeight: 'bolder',
                       
                    }
                },
                {
                    props: {color:'text'},
                    style:{
                        color: 'white'
                    }
                },
                {
                    props: {color:'title'},
                    style:{
                        color: hometheme.palette.secondary.main
                    }
                },
                {
                    props:{size:'small'},
                    style:{
                        fontSize: '.8rem'
                    },
                  
                },
                {
                    props:{size:'medium'},
                    style:{
                        fontSize: '1rem'
                    },
                  
                },
                {
                    props:{size:'large'},
                    style:{
                        fontSize: '1.2rem'
                    },
                  
                },
                {
                    props:{size:'xlarge'},
                    style:{
                        fontSize: '2rem'
                    },
                    
                }

            ],
            

        },
        MuiDivider:{
            styleOverrides:{
                root:{
                    // marginTop:'1rem',
                    borderColor:hometheme.palette.secondary.main,
                    // marginBottom:'1rem',

                    
                }
            },
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    // color:hometheme.palette.primary.main,
                    color:'white',
                    fontSize:'2.5rem'
                }
            }
        },
       
      
    },
    
  });
export {theme};
