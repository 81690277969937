import { CircularProgress, Divider, Grid, Link, Paper, Popover, ThemeProvider, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SizeContext } from '../../App';
import { ColorBlockedImage } from '../../reusables/ColorBlockedImage';
import { theme as hometheme } from '../Home/Home.theme';
import { useLoadData } from '../Hooks/useLoadData';

import img from "../../assets/images/WOG_0807_HDR-3a.jpeg";

export function AboutWhere() {
    const [isLoading, setisLoading] = useState(true);
    const matches = useContext(SizeContext);
    const collection = 'about/where';

    const [aboutStatus,aboutError,about] = useLoadData({collection:collection});

    useEffect(()=>{
        if(aboutStatus == 'success'){
            setisLoading(false);
        }
    },[aboutStatus]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open,setopen] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setopen(event.currentTarget.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setopen(null);
    };

    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    return (
        <ThemeProvider theme={hometheme}>
            <Grid 
                variant="mainContainer"
                style={{
                    // border:'2px solid purple',
                    flexDirection:'column',
                    
                }}
            >
                 <Grid
                    variant="pageImageGrid"
                    >
                    <img
                        style={{
                            
                            height: 'auto',
                            filter: 'grayscale(100%)',
                            width: {matches}?"100%":"",
                            boxShadow:`0px 6px 11px 3px ${hometheme.palette.grey}`,
                            opacity:'0.5'
                        }}
                        src={img} />
                </Grid>
                {(isLoading) ? 
                <Grid display="flex" flexDirection={"column"} alignItems={"center"} minWidth={"100%"} minHeight={"100%"} justifyContent={"center"}>
                    <CircularProgress style={{color:hometheme.palette.primary.main}} />
                </Grid>
                :
                
                <Grid 
                variant="sectionContent"
                style={
                    matches?{}:
                    {
                    // border:'2px solid blue',
                    position:'absolute',
                    width:'60%',
                    alignSelf:'center'
                }}
                    >
                    <Grid 
                        variant="textGrid"
                        style={matches?
                            {
                            alignItems:'center'
                        }:{}}
                        >
                        <Typography variant="h3" >About White Oak</Typography>
                        <br/>
                        <Divider sx={{borderColor: hometheme.palette.primary.main}}/>
                        <Typography variant="body1">
                            In the year of our Lord 1905, a group of dedicated believers recognized a need to organize a Baptist church in their community. 
                            With only a “widow’s mite” and strong determination, members of the Comer, Donahue, 
                            Milton and Wallington families began building a “mission” under a brush arbor in their small northeast Greensboro community, 
                            known as Smith Village.

                        </Typography>
                        <Typography variant="body1">
                        As the founding families prepared for their first revival, a storm developed and destroyed their arbor, but not their faith. 
                        The founders constructed another arbor and conducted their revival as planned.
                        </Typography>
                    </Grid>
                    <br></br>
                    <br></br>
                    {!(about.length<1) && <div><br/> <br/></div>}
                    {about.map((section,index) => {
                        return(         
                            <Grid
                                variant="sectionContentMain"
                                style={matches?
                                    {backgroundColor:'transparent'}:
                                    {
                                    position:'relative',
                                    marginLeft:(index%2!=0)?'70%':'5%',
                                    marginTop:(index%2!=0)?'-15vh':0,
                                    backgroundColor:'transparent',
                                    // width:'fit-content'
                                }}
                            >
                                {!( about.indexOf(section) == about.length-1) && <Divider />}
                                {section.image?  
                                    <Grid
                                        variant='imageGrid'
                                        style={matches?
                                            {
                                            marginLeft:50,
                                            // width: 'auto',
                                        }:{}}
                                    >
                                        <ColorBlockedImage 
                                            srcImage={section.image} />
                                    </Grid>
                                    :
                                    <></>}
                                    <Grid 
                                        variant="textGrid"
                                        style={matches?
                                        {
                                            alignItems:'center'
                                        }:{}}
                                    >
                                        <Paper elevation={8} square sx={{
                                            width:'fit-content',
                                            backgroundOrigin:'content-box',
                                            backgroundPosition:'110% 75%',
                                            p:1,
                                            // border:'2px solid green',
                                            backgroundColor:hometheme.palette.cream.main,
                                            boxShadow:`0px 27px 0px 9px ${hometheme.palette.grey.main}`,
                                            
                                        }}> 
                                            <Typography 
                                                id={section.id}
                                                component={Link}
                                                underline='none'
                                                variant="h4"
                                                sx={{
                                                    '&:hover':{
                                                        // transform: `scale(.5)`,
                                                        color:hometheme.palette.info.main,
                                                        backgroundColor:'transparent'
                                                    },
                                                    cursor: 'pointer'

                                                }} 
                                                onClick={(e)=>handleClick(e)}
                                                >
                                                {section.title}
                                            </Typography>
                                        </Paper>
                                        
                                        {section.subtitle? 
                                        <Typography variant="h6">{section.subtitle}</Typography> 
                                        : 
                                        <></>}
                                    </Grid>
                                    <Popover
                                        id={section.id}
                                        open={open==section.id}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        elevation={10}
                                        slotProps={{
                                            paper:{
                                                sx:{
                                                    width:'60vw'
                                                }
                                        }}}
                                       
                                    >
                                        <Typography sx={{ p: 2 }}>{section.body}</Typography>
                                    </Popover>
                            </Grid>     
                        );
                    })}
                </Grid>}
               
            </Grid>
        </ThemeProvider>
    );
}