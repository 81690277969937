import { Backdrop, CircularProgress, Grid } from "@mui/material"


export const LoadingDisplay = () => {
    return (
      <Grid variant='herogridloading'>
        <Backdrop
          sx={{ 
            
            // color: '#fffff', 
          
          zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
          >
        <CircularProgress />
        </Backdrop>
      </Grid>
    )
}