import { Avatar, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import React from 'react';
import { theme as hometheme } from '../Home/Home.theme';
import { theme } from './Events.theme';

export function EventContent({section}) {
  return (
    <ThemeProvider theme={theme}>
      <Paper key={section.id} elevation={3} 
        style={{width:'100%'}} 
        >
        <Grid 
         key={`Grid1_${section.id}`}
        display="flex"
        flexDirection="column"
        padding={'3vh'}
        > 
        
          <Typography
           key={`Typog1${section.id}`}
          >{
            section?.date
            ?
              (section?.date.seconds)
              ? 
                dayjs.utc(new Timestamp(section.date.seconds,section.date.nanoseconds).toDate()).format('MMMM DD, YYYY @ h:mm A')
              :
              (section.date._seconds)
              ? 
              dayjs.utc(new Timestamp(section.date._seconds,section.date._nanoseconds).toDate()).format('MMMM DD, YYYY @ h:mm A')
              : 
              dayjs.utc(new Date(section.date)).format('MMMM DD, YYYY @ h:mm A')
            :""
          }</Typography>
          <Typography  key={`Typog2${section.id}`} variant="eventtitle">{section.title}</Typography>
          <br/>
          {section.image? 
            <img  key={`img${section.id}`} style={{width:'100%'}}src={section.image}></img>
          : 
          <></>}
          {section.description && <br></br>}
          {section.description &&<Typography  key={`Typog3${section.id}`} variant="body">{section.description}</Typography>}
          {section.location &&<br></br>}
         {section.location && <Typography  key={`Typog4${section.id}`} variant="body">Location: {section.location}</Typography>}

          {(section.contact?.length>0) 
          &&
          <Grid  key={`Grid1${section.id}`}>
            <ThemeProvider theme={hometheme}>
            {/* <Typography variant="h6">Contact</Typography> */}
            </ThemeProvider>
            <Grid key={`Grid2${section.id}`} variant="ministryContactGrid">
                {section.contact.map((contact,index)=>{
                  return(
                                  <Grid
                                      key={`contact_${index}`}
                                      variant="ministryContactGrid"
                                      
                                  >
                                      {contact.image?
                                          <div>
                                              <Avatar  key={`avatar_${index}`} src={contact.image}></Avatar>
                                              <Grid  key={`Grid1_${index}`} style={{ display:'flex',width:'1rem'}}></Grid>
                                          </div>
                                          :<></>
                                      }
                                      
                                      <Grid 
                                          key={`Grid2_${index}`}
                                          variant="ministrySectionContent">
                                          {contact.name 
                                          &&
                                          <Typography key={`Typog1_${index}`} variant="h6" noWrap sx={{fontWeight:'bold'}}>{contact.name}</Typography> 
                                          }   
                                          {contact.title 
                                          &&
                                          <Typography key={`Typog2_${index}`} variant="h7" noWrap sx={{color:hometheme.palette.primary.main}}>{contact.title}</Typography> 
                                          }  
                                          {contact.email &&
                                          <Typography key={`Typog3_${index}`} variant="body1" noWrap>{contact.email}</Typography> 
                                          }
                                          
                                          {contact.phone &&
                                          <Typography key={`Typog4_${index}`} variant="body1" noWrap>{contact.phone}</Typography> 
                                          }
                                          {contact.address &&
                                          <Typography key={`Typog5_${index}`} variant="body1" noWrap>{contact.address}</Typography> 
                                          } 
                                      </Grid>
                                      <Grid key={`Grid3_${index}`} style={{ display:'flex',width:'2.5rem'}}></Grid>
                                  </Grid>
                        )
                  })}
            </Grid>
          </Grid>
          }   
          <br/>
          {section.links?.length>0? 
           <Grid key={`Grid4${section.id}`} display={'flex'} flexDirection={"column"}>
            <Typography key={`Typog6${section.id}`}>Links:</Typography>
            {section.links.map(link=>
              <Typography key={`Typog7${section.id}`} variant="body"><a href={link} target="_blank">{link}</a></Typography>
              )}
            </Grid>
          :<></>}
           <br/>
          {section.documents?.length>0? 
          <Grid  key={`Grid5${section.id}`}>
            <Typography key={`Typog8${section.id}`}>Documents:</Typography>
            {section.documents.map(doc=>
              <Grid>
              <Typography key={`Typog9${section.id}`}variant="body"  >
               
                <a  key={`Link${section.id}`} href={`${doc.content}`} target="_blank">{doc.name.split('_').slice(2).join("")}</a>
              </Typography>
             
              </Grid>
            )}
          </Grid>
          :<></>}            
        </Grid>
      </Paper>
   </ThemeProvider>
  );
}