import {  Outlet, useLocation } from 'react-router-dom';
import { Header } from '../Header/Header';
import {Footer} from '../Footer/Footer';
import { ThemeProvider } from '@mui/material';
import {theme} from '../Home/Home.theme';

export const AppLayout = () => {
    const headerFade = false;

    return(

        <ThemeProvider theme={theme}>
            <Header fade={headerFade}></Header>
                <Outlet/>
            <Footer></Footer>
        </ThemeProvider>
    )
}
