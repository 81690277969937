import { AppBar, Button, Grid, Link, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import woglogo from '../../assets/woglogo.png';
import { CustomSuccessAlert } from "../../reusables/CustomAlert";
import { theme } from "../CMS.theme";
import { useAuth } from "./useAuth";


export const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { login ,resetPass} = useAuth();
    const [forgotPass,setforgotPass] = useState(false);
    const [disabled,setdisabled ] = useState(true);
    const [status,setstatus] = useState();
    const handleLogin = async (e) => {
        e.preventDefault();
        // Here you would usually send a request to your backend to authenticate the user
        // For the sake of this example, we're using a mock authentication
       login({username,password})
    };
    const [headerHeight,setheaderheight] = useState();
    useEffect(()=>{
        setheaderheight(document.getElementById('app-header').offsetHeight);
    },[])
    const handlePasswordReset = () =>{
        resetPass(username).then((resp)=>{
            setstatus(resp)
        }  
        )
        setTimeout(()=>{
           setstatus();
           setforgotPass(false);
        },2000)
    }
    const validate = (e) => {
        let username = e.target.value;
        setUsername(username)
        setdisabled(false);
        if (!username) {
            setdisabled(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
          setdisabled(true);
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <AppBar 
            id='app-header'
            variant='login'
            position="fixed" 
            color="inherit"
            >   
            <Grid 
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                }}
            >
              <Grid 
                style={{
                    position:'fixed',
                    paddingLeft:70,
                }}
              >
                {(woglogo != "") ?  
                  <img style={{width:'18rem', objectFit:'contain'}}src={woglogo} onClick={()=>window.open('/admin/login')} />
                :
                  <div style ={{height:"10vh"}}></div>}
              </Grid>
              <Grid
                style={{
                    height:'15vh',
                    width:'100vw',
                    alignContent:'center'
                }}
              >
                <Typography 
                    variant="h3"
                    color={'white'}
                    style={{
                        textAlign:'center',
                        alignSelf:'center'
                    }}
                >Admin Dashboard</Typography>
              </Grid>
            </Grid>
          </AppBar>
            <Grid 
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    marginTop:`${headerHeight}px`,
                    padding:'5%',
                }}
            >
               {status == "success" && <CustomSuccessAlert message={"An email has been sent to reset your password."}/>}
               {status =="error" && <CustomSuccessAlert message={"Sorry, we are unable to send password email."}/>}
                {!forgotPass &&
                <Grid
                >
                    <form 
                        style={{
                            marginBottom:20
                        }}
                        onSubmit={handleLogin}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleLogin(e);
                            }}
                        >
                        <Grid
                            style={{
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                            }}
                        >
                            <Typography variant='eventtitle'>Welcome Admin, please login.</Typography>
                            <Grid
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                width:'fit-content',
                                marginTop:'5vh',
                            }}
                            >
                            <label htmlFor="username">Username: </label>
                            <input
                                id="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            </Grid>
                            <Grid
                            sx={{
                                width:'fit-content',
                                marginTop:'2vh',
                                marginBottom:'2vh'
                            }}
                            >
                            <label htmlFor="password">Password: </label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            
                            </Grid>
                            <Link component="button" onClick={()=>setforgotPass(true)}>Forgot Password?</Link>
            
                            <Button type="submit"  
                                >
                            Login</Button>
                            
                        </Grid>
                    </form>
                </Grid>
                }
                {forgotPass && 
                <form 
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                 
                    >
                    <Grid
                     style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                    >
                        <Typography variant='eventtitle'>Reset Password</Typography>
                        <Grid
                        sx={{
                            width:'fit-content',
                            marginTop:'5vh',
                            
                        }}
                        >
                        <label htmlFor="username">Username: </label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => validate(e)}
                        />
                        </Grid>
                        <Grid
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                // alignItems:'space-between',
                            }}
                        >
                            <Button 
                            style={{
                                marginRight:'2vh'
                            }}
                            disabled={disabled}
                            onClick={()=>handlePasswordReset()}>Reset Password</Button>
                            <Button onClick={()=>setforgotPass(false)}>Cancel</Button>
                        </Grid>
                       
                    </Grid>
                </form>}
            </Grid>
        </ThemeProvider>
  );
};