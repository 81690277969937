import { Grid } from "@mui/material";
import { useAuth } from "./auth/useAuth";

export const Secret = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Grid 
                sx={{
                    marginTop:'90px',
                    padding:'5%',
                    border:'2px dashed red',

                }}
            >
      <h1>404 Page Not Found</h1>
      {/* <button onClick={handleLogout}>Logout</button> */}
    </Grid>
  );
};