// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
let firebaseConfig = require("./firebase-configuration.json");
// Initialize Firebase
export function app () {

    const fbApp = initializeApp(firebaseConfig);
    return fbApp;
}
// export default app;

