import { Close } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../../cms/CMS.theme";
import { publish } from "../utils/CommitChanges";

const PublishModal = ({openPublish,setOpenPublish,setCommitResponse})=>{
   
    const handlePublish = (e) =>{
        setCommitResponse({status:'processing',message:""});
        setOpenPublish(false);
        publish(e).then(response=>{
            setTimeout(()=>{
                setCommitResponse({status:'success',message:"Your changes are now live!"});
           
           
            setTimeout(()=>{
                setCommitResponse("");
                setOpenPublish(false);
            },2000);
        },6000)
        }).catch((error)=>{
            console.error('Error publishing - ',error);
            setCommitResponse({status:'error',message:" There was an issue trying to publish changes, please try again."});
            setTimeout(()=>{
                setCommitResponse("");
                setOpenPublish(true);
            },2000)
        })
    }
    return (
        <ThemeProvider theme={theme}>
        <Modal
        open={openPublish}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            }}> 
            {(JSON.parse(sessionStorage.getItem('changes')))?
            <Grid
            style={{display: "flex",
                flexDirection: "column",
                padding:20
            }}
            >
                <Close style={{alignSelf:'flex-end'}}onClick={()=>setOpenPublish(false)} />
                <Typography style={{alignSelf:'center',fontWeight:'bold'}} variant="prayer" id="modal-modal-title">
                    You have unsaved changes.
                </Typography>
                <Divider/>
                <Typography id="modal-modal-title" >
                    Save your current changes before publishing.
                </Typography>
            </Grid>
            :
            <Grid style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                
                <Typography >
                    Are you sure you want to publish all changes to the live website? 
                
                </Typography>
                <br></br>
                <Typography>   
                    All changes will take effect immediately. 
                </Typography>
                <Typography>   
                    Note: This action cannot be undone.
                </Typography>
                <Grid style={{display:'flex',justifyContent:'center'}}>
                    <Button 
                    onClick={(e)=>handlePublish(e)}
                    >Yes,Publish</Button>
                    <Button 
                    onClick={()=>setOpenPublish(false)}
                    >No,Cancel</Button>
                </Grid>
                
            </Grid>
            }
            </Box>
    </Modal>
    </ThemeProvider>
    )
}
export default PublishModal;