import "@fontsource/league-spartan";
import "@fontsource/league-spartan/100.css";
import "@fontsource/league-spartan/600.css";
import "@fontsource/league-spartan/700.css";
import "@fontsource/league-spartan/900.css";

import design from '../../assets/entiredesign.png';
// import design from '../../assets/images/background-pattern.svg';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme(
  {
    palette: {
      primary: {
        // light: '#757ce8',
        main: '#661414',
        // dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        // light: '#757ce8',
        main: '#462234',
        // dark: '#002884',
        contrastText: '#fff',
      },
      info:{
        // light: '#757ce8',
        main: '#E5BD21',
        // dark: '#002884',
        contrastText: '#462234',
      },
      bg: {
        main: '#F8F4E4'
      },
      cream:{main: 'rgba(248, 244, 228, 1)'},
      text: {main:'white'}, //rgba(255, 255, 255, 1)
      title: {main:'#462234'},//dark purple - rgba(70, 34, 52, 1)
      body: {main:'black'}, //rgba(0, 0, 0, 1)
     //yellow -rgba(229, 189, 33, 1)
      highlight:{main: '#E5BD21'}, //yellow
      grey: {main:'#a9958e'}
    },
    typography:{
      fontFamily: [
        "League Spartan",
        'Puzzled'
      ],
     
    },
  }
);
theme = responsiveFontSizes(theme);
theme = createTheme(theme,{
  components:{
    MuiGrid:{
      variants:[
        {
          props: {variant: 'main'},
          style:{
            backgroundColor:'white',
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)),
                      url(${design})`,
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-evenly",
            // border:'4px dashed orange',
            // padding:'20vh',
            // width:'100%',
            backgroundPositionX: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            // backgroundSize: 'auto',
            // backgroundRepeat: 'repeat',
            alignItems: "center",

          }
        },

//About Theme
        {
          props:{variant: 'sectionContainer'},
          style:{
              // border: `4px dashed red`,
              // width:"fit-content",
              alignItems:'center',
              display:"flex",
              alignContent:'center',
              width:'100%',
              // height: '70vh',
              // boxShadow: '0 0 8px 8px white inset',

              flexDirection:"column",
              [theme.breakpoints.down('md')]:{
                // border: `4px dashed red`,
              }
          }
        },
//AboutWhere Theme 
        {
          props:{variant: 'mainContainer'},
          style:{
              // border: `4px dashed red`,
              // width:"fit-content",
              display:"flex",
              justifyContent:'space-between',
              width:'100%',
              // height: '70vh',
              // boxShadow: '0 0 8px 8px white inset',
              [theme.breakpoints.down('sm')]: {
                flexDirection:'column',
                alignItems:'center'
              },
              flexDirection:"row",
          }
        },
        {
          props:{variant: 'pageImageGrid'},
          style:{
              //  border: `4px dashed blue`,
              // position:'absolute',
              
              marginLeft:'19%',
              marginTop:'20vh',
              // zIndex:'-10',
              width:"90vh",
              display:"flex",
              // width:'auto',
              height: '100vh',
              [theme.breakpoints.down('sm')]: {
                width:'70vw',
                height: 'auto',
                marginTop:0,
                marginLeft:0,
                marginBottom:'5%'
              },
              // boxShadow: '0 0 8px 8px white inset',

              // flexDirection:"column",
          }
        },
        {
          props:{variant: 'sectionContent'},
          style:{
            display:"flex",
            flexDirection:"column",
            // border: `4px dashed blue`,
            width:"100%",
            [theme.breakpoints.down('sm')]: {
              width:"100%",
              alignItems:'center'
            },
          }
      },
      {
        props: {variant:'sectionContentMain'},
        style:{
            display:'flex',
            width:'100%',
            marginBottom:'10vh',
            backgroundColor:'rgba(255,255,255,0.8)',
            [theme.breakpoints.down('md')]:{
              flexDirection:'column',
              alignItems:'center',
              marginBottom:'4vh',
            }
            // border:'2px solid red',
        }
    },


//AboutWho Theme
        {
          props: {variant: 'aboutContainer'},
          style:{
            // backgroundColor:'white',
            // backgroundImage:`url(${design})`,
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-evenly",
            // border:'4px dashed orange',
            // padding:'20vh',
            width:'fit-content',
            // backgroundPositionX: 'center',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            alignItems: "center",

          }
        },
       


//Home Hero Themes
        {
          props: { variant: 'herogrid'},
          style:{
            display:'flex',
            flex:1,
            flexDirection:'column',
            alignItems: 'center',
            width:'100%',
            height:'100vh',
            backgroundColor:'black',
            
          }
        },
        {
          props: { variant: 'smallherogrid'},
          style:{
            display:'flex',
 
            flexDirection:'column',
            alignItems: 'center',
            width:'100%',
            height:'100%',
            backgroundColor:'black',
            overflowX:'hidden',
            
          }
        },
        {
          props: {variant: 'herogridloading'},
          style:{
            display:'flex',
            flexDirection:'column',
            alignItems: 'center',
            justifyContent:'center',
            width:'100%',
            height:'100vh',
            
            // background:'transparent',
            // border:'3px dashed red',
          }
        },
        {
          props: { variant: 'herocontent'},
          style:{
            marginTop:"15vh",
            width:'50vw',
            my:'15',
            alignItems:"center",
            gap:'1',
            minWidth:"fit-content",
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            // border: '2px solid grey', 
            top:'20%', minHeight:"fit-content",
            maxHeight:'80vh', 
            position:'absolute'
          }
        },
        {
          props:{ variant: 'announcementBlock'},
          style:{
            display:"flex", 
            flexDirection:"column",
            alignItems:'center',
            width:'100%',
            padding:'2rem',
            // height:'70vh',
            [theme.breakpoints.down('sm')]: {
              padding:0
            },
            //  border:'4px dashed orange',
          }
        },
        {
          props:{ variant: 'announcementSection'},
          style:{
            display:"flex", 
            flexDirection:"column",
            alignItems:"center",
            minHeight:"fit-content",
            //  marginTop:"10vh",
            paddingTop:'3rem',
            width:'70%',
            // height:'100%',
              // paddingBlock:'10vh', 
            // marginBottom:'10vh',
            //  backgroundColor: theme.colors.title
             
            //  border:'4px dashed blue',
             [theme.breakpoints.down('sm')]: {
                width:'100%',
                paddingTop:0
             },
          }
        },
        
//TODO: Label the rest of the themes
       

//Event Theme
        {
          props: {variant: 'eventContainer'},
          style:{
            // backgroundColor:'white',
            // backgroundImage:`url(${design})`,
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-evenly",
            // border:'4px dashed orange',
            // padding:'20vh',
            width:'100%',
            // backgroundPositionX: 'center',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            alignItems: "center",

          }
        },
        {
          props:{ variant: 'eventSectionPortrait'},
          style:{
            display:"flex", 
            flexDirection:"column",
            alignItems:"center",
            marginTop:'2%',
            width:'100%',
            //  border:'4px dashed red',
          }
        },
        {
          props:{ variant: 'eventSectionMain'},
          style:{
            display:"flex", 
            flexDirection:"column",
            alignItems:"center",
            minHeight:"fit-content",
            paddingTop:'3rem',
            maxWidth:'80%',
            width:'100%',
            [theme.breakpoints.down('md')]:{
              maxWidth:'100%',
              width:'100%'
            }
            // width:'55%',
            //  border:'4px dashed blue',
          }
        },
        {
          props:{variant:'eventImg'},
          style:{
            display:"flex",
            // flex:1, 
            justifyContent:'center',
            height:'auto',
            minWidth: '55%',
            // minWidth:'35vw',
            maxWidth:'70%',
            [theme.breakpoints.down('md')]:{
              maxWidth:'85%'
            }
            // border:`1px dashed ${theme.palette.primary}`,
            // maxWidth:'50%'
          }
        },
        {
          props: { variant: 'eventsection'},
          style:{
            display:"flex",
            flexDirection:"row",
            width:"60vw",
            marginTop:'2rem',
            justifyContent:'center',
            // border:'4px dashed orange',

          }
        },
        {
          props: { variant: 'eventtext'},
          style:{
            display:"flex", 
            flexDirection:'column', 
            width:'fit-content',
            marginTop:'2rem',
            marginLeft:'2vw',
            [theme.breakpoints.down('md')]:{
              alignItems:'center',
              marginTop:'3vh',
              width:'100%',
            }
            // paddingLeft:'5vw',
            // paddingRight:'5vw',
            // border:'4px dashed red',
          }
        },
        {
          props:{ variant: 'linksSection'},
          style:{
            display:"flex",
            justifyContent:"space-evenly",
            flexDirection:"row",
            // marginTop:'15vh',
            height:"65vh",
            // width:'100%',
            
                //  paddingTop: "18%",
            // border:'4px dashed red',
            backgroundSize:'cover',
            paddingInline:'10vw',
            backgroundColor:'rgba(0,0,0,0.7)',
            backgroundBlendMode:'darken',
            //  opacity:'0.9'
          }
        },     
        {
          props: { variant: 'linksSectionContent'},
          style:{
            // border:'4px dashed red',
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:'center',
            width:'25%',
            [theme.breakpoints.down('md')]:{
              width:'75%',
              height:'33%'
            }
          }
        },
        {
          props: { variant: 'pastorsection'},
          style:{
            display:"flex",
            flexDirection:"row",
            width:"100%",
            // border:'4px dashed red',
            alignItems:'center',
            justifyContent:'space-evenly',
            paddingTop:'5rem',
            [theme.breakpoints.down('md')]:{
              paddingTop:0,
              flexDirection:"column",
              paddingInline:'5vw',
              marginTop:"10%",
            }
          }
        },
       
        {
          props: { variant: 'pastorText'},
          style:{
            display:"flex", 
            flexDirection:'column', 
            flex:1,
            alignItems:"center",
            maxWidth:'40%',
            backgroundColor:'rgba(255,255,255, 0.8)',
            [theme.breakpoints.down('md')]:{
              maxWidth:'100%',

            }
          }
        },
        {
          props:{ variant: 'sermonSection'},
          style:{
            position:'relative',
            display:"flex", 
            flexDirection:"column",
            alignItems:"center",
            width:'100%',
            // minHeight:"fit-content",
            marginTop:"3rem",
            // marginBottom: '15vh',
            // paddingBlock:'10vh',
            height:'80vh',
            // marginBottom:'10vh',
            justifyContent:'center',
            backgroundColor: 'black',
            [theme.breakpoints.down('md')]:{
              marginTop:"10%",
              marginBottom: '7vh',
              height:'30vh',
            }
            //  border:'4px dashed blue',
          }
        },
//Connect.js Theme Variables
        {
          props: {variant:'connectmain'},
          style:{
              // borderTop:`5px solid ${hometheme.palette.primary}`, 
              // height:"50vh",
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              width:'100%',
              [theme.breakpoints.down('sm')]:{
                // border:'2px dashed blue',
              },
              
              // paddingBlock:"10vh"
              
          }
        },
        {
        props:{variant: 'connectsectionContent'},
        style:{
            // border: `4px dashed orange`,
            // width:"80vw",
            display:"flex",
            width:"100%",
            // flexDirection:"column",
            alignItems:"center",
            flexDirection:'row',
            minHeight:"50vh",
            justifyContent:'center',
            [theme.breakpoints.down('sm')]:{
              // border:'2px dashed blue',
              flexDirection:'column',
            },
            //  maxWidth:'75%'

        }
        },
        {
          props:{variant:'wordsContainer'},
          style:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            width:'fit-content',
            justifyContent:'center',
            
            // border:'2px solid red'
            [theme.breakpoints.down('sm')]:{
              
            }
          }
        },
//Visit.js Theme Variables
        {
          props: {variant:'visitmain'},
          style:{
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              minHeight:"fit-content", 
              width:'100%',
              marginBottom:"10vh", 
              justifyContent:'space-between',
              paddingInline:'10vw',
              // border: `4px dashed blue`,
              [theme.breakpoints.down('sm')]:{
                paddingInline:0,
              }
          }
        },
        {
          props: { variant: 'visitimageGrid'},
          style: {
              display:'flex',
              // flex:"1",
              marginTop:'10vh',
              marginBottom:'10vh',
              justifyContent:"center",
              width:'100%',
              // maxHeight:"60vh"
             
          }
        },
        {
          props:{variant: 'visittextGrid'},
          style:{
              // border: `4px dashed red`,
              display:"flex",
              width:'100%',
              flexDirection:"row",
              // alignItems:"center",
                minHeight:"fit-content", 
                alignItems:'center',
                marginBottom:"10vh", 
                justifyContent:'space-between',
                marginTop:'15vh',
              // width:'fit-content',
              // justifyContent:'center'
              [theme.breakpoints.down('md')]:{
                flexDirection:"column",
                alignItems:'center',
                marginTop:0,

              }
          }
        },
//Ministry.js Theme Variables
        {
          props: {variant:'ministrymain'},
          style:{
              // border:`5px dashed ${theme.palette.primary.main}`, 
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              width:'100%',
              [theme.breakpoints.down('md')]:{
               
            
              }
          }
        },

        {
          props:{variant: 'ministrysectionContent'},
          style:{
              // border: `4px dashed ${orange[500]}`,
              width:"100%",
              display:"flex",
              flexDirection:"column",
              [theme.breakpoints.down('md')]:{
                
                alignItems:"center",
                //marginTop:"5vh",
                minHeight:"20vh",
                width:'90%'
              }

          }
        },
        {
          props:{variant: 'ministrysmallsectionContent'},
          style:{
              // border: `4px dashed ${orange[500]}`,
              // width:"80vw",
             
            

          }
        },
        //Ministry Theme
        {
          props: { variant: 'textGrid'},
          style:{
            // border: `4px dashed purple`,
            display:'flex',
            // flex:1,
            flexDirection:'column',
            // alignItems: 'center',
            alignSelf: 'center',
            width:'100%',
          
            
          }
        },
        {
          props: { variant: 'ministryGrid'},
          style:{
            border: `4px dashed blue`,
            display:'flex',
            // flex:1,
            flexDirection:'row',
            // alignItems: 'center',
            // justifyContent:'center',
            alignSelf: 'center',
            width:'100%',
            [theme.breakpoints.down('md')]:{
              
            }
          
            
          }
        },
        {
          props: { variant: 'ministrySectionContent'},
          style:{
            // border: `4px dashed red`,
            display:'flex',
            // flex:2,
            flexDirection:'column',
            // alignItems: 'center',
            alignSelf: 'center',
            width:'fit-content',
            
            
          }
        },
        {
          props: { variant: 'ministryContactGrid'},
          style:{
            // border: `4px dashed purple`,
            display:'flex',
            // flex:1,
            flexDirection:'row',
            // alignItems: 'center',
            alignSelf: 'center',
            width:'100%',
            [theme.breakpoints.down('md')]:{
              flexDirection:'column',
              alignItems:'center',
             
            }
           
          
            
          }
        },
        {
          props: { variant: 'imageGrid'},
          style:{
            // border: `4px dashed orange`,
            display:'flex',
            // flex:1,
            // flexDirection:'column',
            // alignItems: 'center',
            // alignSelf: 'center',
            // width:'75%',
          
            
          }
        },

//SectionContent.js
        {
          props: {variant:'SectionContentMain'},
          style:{
              display:'flex',
          //  position:'relative',
              alignSelf:'center',
              width:'70vw',
              // marginBottom:'1vh',
              height:'auto',
              backgroundColor:'rgba(255,255,255,0.8)',
              justifyContent:'center',
             
              // border:'2px solid orange',
          }
        },
        {
          props: { variant: 'SectionContentImageGrid'},
          style: {
              display:'flex',
              flex:1,
              padding:'1rem',
              marginRight:'2vw',
              position: 'relative',
              width:'100vw',
              // width: `calc(50% - 105px)`,
              // margin: '35px',
              justifyContent:'flex-end',
              // marginRight:'10vw',
              // border: `4px dashed ${orange[500]}`,
              [theme.breakpoints.down('md')]:{
                alignSelf:'center',
                justifyContent:'center'
              }
          }
        },
        {
          props:{variant: 'SectionContentTextGrid'},
          style:{
              // border: `4px dashed ${red[500]}`,
              display:"flex",
              flex:1,
              flexDirection:"column",
              justifyContent:'center',
              // minWidth:"50%"

          }
        }
      ]
    },
    MuiCard:{
      components:{
        variants:[
          { 
            props:{ variant:'sermoncontainer'},

            style:{
              width:"80vw",
              height:"10vh"
            }
          },
        ]
      }
    },
    MuiCardMedia:{
      components:{
        variants:[
          { 
            props:{ variant:'sermon'},

            style:{
              // width:"100%",
              // height:"100%"
            }
          },
        ]
      }
    },
    MuiButton: {
      styleOverrides :{
        root:{
          color: 'white',
          backgroundColor: theme.palette.primary.main,
          fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
          // fontFamily: theme.font.text,
          fontWeight: 'bold',
          fontFamily:'League Spartan',
          textTransform: 'uppercase',
          marginTop: "2rem",
          border: "none",
          width: "fit-content",
          height: "fit-content",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
          paddingTop: ".8rem",
          paddingBottom: ".8rem",
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            border:`1px solid ${theme.palette.primary.main}`,
           
           },
          [theme.breakpoints.down('md')]:{
           
            fontSize: '0.8rem'
          }
           
                
        } 
      }
    },
    MuiTypography:{
      styleOverrides:{
        root:{
          fontFamily: 'League Spartan',
          textTransform: "uppercase",
          [theme.breakpoints.down('md')]:{
            textAlign:'center'
          }
        },
        h2:{
          // fontSize:'44px',
          color: theme.palette.primary.main,
          fontWeight: 400
        },
        h3:{
          // fontSize:'24px',
          // color: 'black',
          // fontWeight: 'bold',
          color: theme.palette.primary.main,
        },
        h4:{
          // fontSize:'20px',
      
        },
        body1:{
          // fontSize:'18px',
          textTransform: 'none',
          // fontSize: '1,2rem',
        },
        body2:{
          // fontSize:'16px',
        },
        button:{
          textTransform: 'uppercase',
          fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontFamily:'League Spartan'
        }, 
       
      },
        variants:[
          //Hero Theme
          { 
            props: {variant:'welcome'},
            style:{
              fontWeight: 'lighter',
              fontFamily: 'Puzzled',
              textTransform: "none",
              // textTransform: theme.textTransform.none,
              fontSize: '4.4rem',
              color: "white",
             
            }
          },
         
          {
            props: {variant:'wog'},
            style:{

              color: 'white',
            }
          },
          { 
            props: {variant:'wog', size:'bold'},
            style:{
              fontWeight:'bold',
              fontSize: '4.4rem',
              [theme.breakpoints.down('sm')]: {
                fontWeight:'bold',
                fontSize: '2.8rem',
              }
            }
          },
          { 
            props: {variant:'wog', size:'subtext'},
            style:{
              fontWeight:'normal',
              fontSize: '2.2rem',
              [theme.breakpoints.down('sm')]: {
                fontWeight: 'normal',
                fontSize: '1.8rem',
              }
            }
          },
       
          {
            props: { variant: 'sermonTitle'},
            style:{
              position:'absolute',
              color: 'white',
              fontSize: '2.2rem',
              fontWeight: 'bold',
              left:'-7rem',
              top:'12rem'

              // textTransform: theme.textTransform.none,
            
            }
          },
          {
            props: { variant: 'title'},
            style:{
              color: theme.palette.primary.main,
              fontSize: '2.3rem',

              textTransform: 'none',
              position:'absolute',
              left:'-7rem',
              top:'17rem'
            }
          },
          {
            props: { variant: 'smallsermonTitle'},
            style:{
              position:'absolute',
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              left:'-2rem',
              top:'1rem'

              // textTransform: theme.textTransform.none,
            
            }
          },
          {
            props: { variant: 'smalltitle'},
            style:{
              color: 'white',
              fontSize: '1.3rem',

              textTransform: 'none',
              position:'absolute',
              left:'-2rem',
              top:'3rem'
            }
          },
          {
            props: { variant: 'link'},
            style:{
              display:"flex", 
              // paddingTop:"10%", 
              color: theme.palette.info.main , 
              // fontFamily: theme.font.text, 
              fontWeight: 'bold', 
              fontSize: '1.2rem',
              '&:hover':{
                color:'rgba(240, 240, 240,0.8)'
              }
            }
          },
          // {
          //   props: {variant:'eventtitle'},
          //   style:{display:"flex", 
          //   color: theme.palette.secondary.main ,
          //   fontWeight: 'bold',
          //   fontSize: '1.2rem',
            

          // }
          // },
          {
            props: {variant:'pastortitle'},
            style:{display:"flex", 
            color: theme.palette.secondary.main , 
            //  fontFamily: theme.font.text, 
            fontWeight: 'bold', 
            fontSize: '1.2rem',
            // fontStyle:'italic',
            textTransform:'none'
            

          }
          },
          {
            props: { variant: 'eventbody'},
            style:{
              textTransform: 'none',
              fontSize: '1,2rem',

            }
          },
          {
          props: { variant: 'pastorbody'},
          style:{
            textTransform: 'none',
            fontSize: '1,2rem',
  

          }

        },
          {
            props: { color: 'date'},
            style:{
              fontStyle:'italic',
              fontSize: '1rem',

            }
          }

          

        ] 
    },
    MuiSvgIcon:{
      styleOverrides :{
        root:({ownerState}) => ({
          color: theme.palette.primary.main, 
        })
      },
      variants:[
        {
          props: { variant: 'icon'},
          style:{
            display:"flex", 
            color: theme.palette.primary.main, 
            // fontFamily: theme.font.text, 
            // fontWeight: theme.fontWeight.normal, 
            fontSize: '2.2rem'
          
          }
        },
        //Connect.js variables
        {
          props: { variant: 'connecticon'},
          style:{
            display:"flex", 
            color:'white',
            fontSize:'3.2rem'
          
          }
        },
      ]
    },
    MuiInputBase:{
      styleOverrides:{
        root:{
          fontFamily:"League Spartan",
          '& .MuiInputLabel-root':{
            fontFamily:"League Spartan",
          }
        },
      
      
    }},
    MuiInputLabel:{
      styleOverrides:{
        root:{
          fontFamily:"League Spartan",
        }
      }
    },
    MuiPaginationItem:{
    styleOverrides:{
      root:{
        alignSelf:'center',
        '& .MuiPagination-text':{
          fontFamily:"League Spartan",
        },
        fontFamily:"League Spartan",
        
      
      }
    }},
    MuiPagination:{
      styleOverrides:{
        root:{
          alignSelf:'center',
          '& .MuiPagination-text':{
            fontFamily:"League Spartan",
          }
        
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          fontFamily:'League Spartan',
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontFamily:'League Spartan',
          
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily:'League Spartan',
        }
      }
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          fontFamily:'League Spartan',
          '& .MuiPickersYear-yearButton':{
            fontFamily:"League Spartan",
          }
        }
      }
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          fontFamily:'League Spartan',
        }
      }
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          fontFamily:'League Spartan',
        }
      }
    },
    MuiImageList:{
      styleOverrides: {
        root: {
          // border:'4px dashed orange',
          width: '100%',
          height: 'auto' ,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          }
        }
      }
    }
  }});
  
export { theme };
