
import { createContext, useContext, useMemo,useState } from "react";

import { getFirestore, setDoc, doc, getDoc,deleteDoc } from "firebase/firestore"; //using this for accessing firestore directly from client. bypassing server

import { app } from "../../utils/fb"; 
import { useAuth } from "../auth/useAuth";

const db = getFirestore(app());

const SessionContext = createContext();

export const SessionStorageProvider = ({children}) =>{
    const [sessionStore,setSessionStore] = useState();
    const {user} = useAuth();

    const addToSessionStorage = async (prev) =>{
        await setDoc(doc(db, "sessionStorage",user.uid), {'changes':prev});
    }

    const deleteSessionStorage = async () =>{
        //remove entire session storage object
        await deleteDoc(doc(db, "sessionStorage", user.uid));
    }

    const clearSessionStorage = () =>{

    }
    const getSessionStorage = async ()=>{
        let session = await getDoc(doc(db, "sessionStorage",user.uid));
        if(session.data()){
            // setSessionStore(session.data().changes);
        return session.data().changes;
        }else{
            // setSessionStore(null);
            return false;
        }
    }

    const value = useMemo(
        () => ({
            addToSessionStorage,
            getSessionStorage,
            deleteSessionStorage,
            clearSessionStorage,
            sessionStore,
            setSessionStore
        }),
        [user]
    );

    return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export const useSession = () => {
    return useContext(SessionContext);
}
