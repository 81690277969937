import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Grid, Pagination, Paper, ThemeProvider, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import React, { useMemo, useRef, useState } from 'react';
import { theme } from "./Prayer.theme";
import { UpdateLikes } from "./UpdateLikes";

export function FilteredPrayerList({prayerList,searchText,limit,setLikes,matches}) { 
    const getFilteredPrayerList = (prayerList,searchText)=>{
        if(searchText == ""){
            return prayerList
          }else{
              let filt = prayerList.filter(item => item.request.toLowerCase().includes(searchText.toLowerCase()));
              return filt;
          }
    }
    let filtPrayerList = useMemo(()=> getFilteredPrayerList(prayerList,searchText), [prayerList,searchText]);
    const [page, setPage] = useState(1);
    const handleNameDisplay = (prayer) =>{
        if(prayer.share == 'yes'){
            return prayer.name;
        }else{
            return 'Anonymous';
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const formattedDate = (date) =>{
        let newDate = "";
        try{
            let x = dayjs(new Timestamp(date._seconds,date._nanoseconds).toDate()).format('MM/DD/YYYY');
            if (x == 'Invalid Date'){
                newDate = "";
            }
            else{
                newDate = x;
            }
        }catch(e){  
            console.error('Failed to format date: ',e);
            newDate = "";
        }
        return newDate;
    }
    const getPageCount = Math.ceil(filtPrayerList.length/limit);
    const newList = filtPrayerList.filter(item => item.request.toLowerCase().includes(searchText.toLowerCase()));
    //Handle Opening Likes
    const [open, setOpen] = useState(false);
    const handleOpen = (event) => {
        event.preventDefault();
        let id = event.target.id;
        let select = prayerList.filter(item => item.id == id);
        selected.current = select[0];
        setOpen(true);
    }
    
    let selected = useRef({});
    const handleClose = (event) =>{
        setOpen(false);
    }
    return(
        <ThemeProvider theme={theme}>     
            <Grid variant='main' style={{width:'80%'}}>
                {(limit > 0 ? 
                    newList.slice((page-1) * limit, (page-1) * limit + limit)
                    :
                    newList
                    )
                    .map(prayer => {
                        return(
                            <Paper sx={{ width:'100%',pointerEvents:'none'}} key={prayer.id} elevation={3} >
                                {open?<UpdateLikes likeState={{handleClose,open,selected,setLikes,matches}} /> : <></>}
                                <Typography variant='prayer'>{prayer.request}</Typography>
                                <Typography variant="date">Posted: {formattedDate(prayer.CreatedDate)}</Typography>
                                <Grid variant="postA">
                                    <Typography variant='name'>{handleNameDisplay(prayer)}</Typography>                        
                                    <Grid variant="postB">
                                        <Tooltip title="I Prayed For This">
                                            <ThumbUpOffAltIcon 
                                            id={prayer.id}
                                            onClick={(e)=>handleOpen(e)}
                                            style={{marginRight:'0.2rem',pointerEvents:'auto'}}></ThumbUpOffAltIcon>
                                        </Tooltip>
                                        <Typography>
                                            {prayer.likes > 0? prayer.likes : ""} {/* {numberOfLikes === 0 ? "" : numberOfLikes} */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })
                }
                <Pagination 
                    count={getPageCount}
                    page={(page > getPageCount) ? 1: page}
                    onChange={handleChangePage}
                >
                </Pagination>
            </Grid>
        </ThemeProvider>
    )
}