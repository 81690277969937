import React, {   } from 'react';
  
import { 
Grid,Typography,
} from '@mui/material';

import {theme} from './Home.theme';
import pastor from '../../assets/pastor.png'

export default function  PastorSection({matches}){
    return(
      (matches?
        <Grid variant='pastorsection'
        >
          <Typography variant="h2">Cory A. Graves</Typography>
          <Typography variant='h4' style={{color:theme.palette.info.main, fontStyle:'italic'}}>Lead Pastor</Typography>
          <br/>
          <img style={{display:"flex" ,flex:1, maxHeight:'60vh', maxWidth:'43vh',border:`0px solid ${theme.palette.primary.main}`,
          boxShadow:`-20px -20px 0px 0px ${theme.palette.primary.main}`}} src={pastor}></img>
          <br></br> 
          <Grid variant="pastorText">
            <Typography variant='body1' style={{}}>
            Pastor Graves is a well sought after messenger, and has traveled extensively sharing the good news of Jesus Christ.  His favorite scripture, Psalms 23:1, “The Lord is my Shepherd; I shall not want”, 
            exemplifies his walk of faith. He believes God, no matter what the situation looks like.  His vision is to transform lives, while building God’s Kingdom; to continue to reach not only those that are within the walls of our ministry, 
            but to reach those in our communities that are searching for a deeper, wealthier relationship with the Lord.  As God continues to show him the vision for White Oak Grove, Pastor Graves remains obedient to doing God’s will.  
            He has proven himself to be anointed and appointed by God to preach and teach the Gospel of Jesus Christ.  He has a sincere love for his church family and daily lives the life that he preaches about.  Pastor Graves is a true man of God and a Pastor at heart.
            </Typography>
          </Grid>
        </Grid>
        :
        <Grid variant='pastorsection'
          >
            <img style={{display:"flex" ,flex:1, maxHeight:'60vh', maxWidth:'43vh',
            border:`0px solid ${theme.palette.primary.main}`,boxShadow:`-20px -20px 0px 0px ${theme.palette.primary.main}`}} src={pastor}></img>
            <Grid variant='pastorText'
            >
              <Typography variant="h2">Cory A. Graves</Typography>
              <Typography variant='h4' style={{color:theme.palette.info.main, fontStyle:'italic'}}>Lead Pastor</Typography>
              <br></br>
              <Typography variant='body1' style={{}}>
              Pastor Graves is a well sought after messenger, and has traveled extensively sharing the good news of Jesus Christ.  His favorite scripture, Psalms 23:1, “The Lord is my Shepherd; I shall not want”, 
              exemplifies his walk of faith. He believes God, no matter what the situation looks like.  His vision is to transform lives, while building God’s Kingdom; to continue to reach not only those that are within the walls of our ministry, 
              but to reach those in our communities that are searching for a deeper, wealthier relationship with the Lord.  As God continues to show him the vision for White Oak Grove, Pastor Graves remains obedient to doing God’s will.  
              He has proven himself to be anointed and appointed by God to preach and teach the Gospel of Jesus Christ.  He has a sincere love for his church family and daily lives the life that he preaches about.  Pastor Graves is a true man of God and a Pastor at heart.
              </Typography>
            </Grid>
          </Grid>  
      ) 
    ) 
  }