import { Grid, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import visitVideo from '../../assets/White-Oak-Grove_Welcome_Hosanna.mp4';
import { theme } from '../Home/Home.theme';

export function Visit() {
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <ThemeProvider theme={theme}>
        <Grid 
            variant="visitmain"
        > 
            <Grid style={{ width:'100%' }}>
                <Typography variant="h2">New To White Oak Grove?</Typography>
                <br/>
                <Typography style={{backgroundColor:'rgba(255,255,255,0.8)'}}  variant="h6">Going anywhere for the first time can be intimidating, so we want to let you know exactly what to expect 
                </Typography>
            </Grid>
            <Grid
                variant='visitimageGrid'
            >
                <video controls width="100%" autoPlay muted>
                
                <source src={visitVideo} type="video/mp4" />
                </video>
            </Grid>
            <Grid 
                variant="visittextGrid"
            >
                <Grid
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        textAlign:'center',                    
                        width: matches?'90vw':400,
                        height: matches?'100vw':300,
                        borderRadius: 20,
                        backgroundColor:'rgba(102,20,20,0.5)',

                    }}
                    
                >
                    <Typography 
                        style={{ alignContent:'center',backgroundColor:'white', boxShadow:`0px 0px 8px 1px ${theme.palette.title.main}`,}}
                        variant="h2">
                            Plan Ahead
                    </Typography> 
                </Grid>

                <Grid 
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    textAlign:'center',
                    width:matches?'100%':450,
                    marginTop:matches?'5vh':"",
                }}
                >
                    <Typography 
                    variant="h3">Our Service Times and Locations:</Typography> 
                    <Typography variant="body1">2600 Assembly Road, Greensboro, NC 27405<br></br>
                    Worship Service: 10:00 a.m. each Sunday</Typography> 
                    <br/>
                    <br/>
                    <Typography variant="h3">TRANSPORTATION</Typography> 
                    <Typography variant="body1">Currently suspended until further church updates.</Typography> 
                </Grid>
            </Grid>
            {matches?
            <Grid 
            variant="visittextGrid"
            >
                <Grid 
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:matches?'center':'center',
                    textAlign:'center',
                    width: matches?'90vw':500,
                    height:matches?'100vw': 400,
                    borderRadius: 20,
                    backgroundColor:'rgba(102,20,20,0.5)',
                    marginBottom:matches?'5vh':""
                }}
                >
                    
                    <Typography
                    style={{backgroundColor:'white',boxShadow:`0px 0px 8px 1px ${theme.palette.title.main}`}}
                    variant="h2">NEW MEMBER ASSIGNMENT</Typography> 
                    <br></br>
                    <br></br>
                    <Typography 
                    style={{width:'60%'}}
                    variant="body1">New members are assigned to our church leaders to help in acclimating to White Oak Grove. Our assignments are based on the new member’s last name.</Typography> 
                </Grid>
                <Grid
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignContent:'center',
                    // flex:1,
                    // marginLeft:theme.breakpoints.down('sm')?0:200,
                    // marginTop:theme.breakpoints.down('sm')?20:0,
                    alignItems:matches?'center':"",
                    textAlign:'center',
                    width:matches?'90vw':450,
                }}
            >       
                <Typography variant="h3">IF YOUR LAST NAME START WITH:</Typography> 
                <br></br>
                <Typography variant="body1" paragraph>
                    A – D: Deacon Keltz Summers and Deacon Harold Fuller <br></br> <br></br>

                    E – H: Deacon Berkley Williams <br></br> <br></br>

                    I – M: Deacon Mike Robinson and Deacon Danny Pressley <br></br> <br></br>

                    N – Q: Minister Johnathan Stowe, Deacon Thomas Blackmon and Deacon Larry Yancey <br></br> <br></br>

                    R – U: Minister Johnathan Stowe and Deacon Jerome Logan <br></br> <br></br>

                    V – Z: Minister Johnathan Stowe, Deacon Calvin Gilmore and Deacon Clarence Morehead <br></br> <br></br>

                    Be sure to look at our staff pictures to help identify your Deacon or Minister assignee.</Typography> 
                </Grid>
            </Grid>

            :
            <Grid 
            variant="visittextGrid"
            >
                <Grid
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignContent:'center',
                        alignItems:matches?'center':"",
                        textAlign:'center',
                        width:matches?'90vw':450,
                    }}
                >       
                    <Typography variant="h3">IF YOUR LAST NAME START WITH:</Typography> 
                    <br></br>
                    <Typography variant="body1" paragraph>
                        A – D: Deacon Keltz Summers and Deacon Harold Fuller <br></br> <br></br>

                        E – H: Deacon Berkley Williams <br></br> <br></br>

                        I – M: Deacon Mike Robinson and Deacon Danny Pressley <br></br> <br></br>

                        N – Q: Minister Johnathan Stowe, Deacon Thomas Blackmon and Deacon Larry Yancey <br></br> <br></br>

                        R – U: Minister Johnathan Stowe and Deacon Jerome Logan <br></br> <br></br>

                        V – Z: Minister Johnathan Stowe, Deacon Calvin Gilmore and Deacon Clarence Morehead <br></br> <br></br>

                        Be sure to look at our staff pictures to help identify your Deacon or Minister assignee.</Typography> 
                </Grid>
                <Grid 
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:matches?'center':'center',
                    textAlign:'center',
                    width: matches?'90vw':500,
                    height:matches?'100vw': 400,
                    borderRadius: 20,
                    backgroundColor:'rgba(102,20,20,0.5)',
                }}
                >
                    
                    <Typography
                    style={{backgroundColor:'white',boxShadow:`0px 0px 8px 1px ${theme.palette.title.main}`}}
                    variant="h2">NEW MEMBER ASSIGNMENT</Typography> 
                    <br></br>
                    <br></br>
                    <Typography 
                    style={{width:'60%'}}
                    variant="body1">New members are assigned to our church leaders to help in acclimating to White Oak Grove. Our assignments are based on the new member’s last name.</Typography> 
                </Grid>
            </Grid>
            }
        </Grid>
    </ThemeProvider>
  );
}