import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue,red,orange } from '@mui/material/colors';
import {theme as hometheme } from '../Home/Home.theme';

export let theme = createTheme({
    components: {
        MuiGrid:{
            variants: [
                {
                    props: {variant:'main'},
                    style:{
                        // border:`5px dashed ${hometheme.palette.primary.main}`, 
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width:'100%',
      
                    }
                },
                {
                    props: {variant:'heroGrid'},
                    style: {
                        // border: `4px dashed ${blue[500]}`,
                        display:"flex", 
                        flex:"1",
                        justifyContent:"center", 
                        backgroundColor:'black',
                        width:'100%',
                    }
                },
                {
                    props: { variant: 'sectionTitle'},
                    style: {
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        marginBottom:"10vh",
                        minHeight:"5vh",
                        // border: `4px dashed ${orange[500]}`,
                    }
                },
                {
                    props:{variant: 'sectionContent'},
                    style:{
                        // border: `4px dashed ${orange[500]}`,
                        width:"100%",
                        display:"flex",
                        flexDirection:"column",
                    }
                },
                {
                    props:{variant: 'smallsectionContent'},
                    style:{
                        // border: `4px dashed ${orange[500]}`,
                        // width:"80vw",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        marginTop:"5vh",
                        minHeight:"20vh",
                        width:'90%'

                    }
                }
            ]
        },
        MuiTypography:{
            styleOverrides :{
                root:({ownerState}) => ({
                    fontFamily: 'League Spartan',
              }),
              h2:{
                fontSize:'44px',
                color: hometheme.palette.primary.main,
                fontWeight: 'bold'
              },
              h3:{
                fontSize:'24px',
                color: 'black',
                fontWeight: 'bold'
              },
              h4:{
                fontSize:'20px',
            
              },
              body1:{
                fontSize:'18px',
                textTransform: 'none',
                // fontSize: '1,2rem',
              },
              body2:{
                fontSize:'16px',
              },
              button:{
                textTransform: 'uppercase',
                fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontFamily:'League Spartan'
              }, 
            },
           
            variants:[
                {
                    props: {variant: 'sectiontitle'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '2.2rem'
                        
                    }
                },
                {
                    props: {variant: 'title'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '2.5rem',
                        
                        textTransform:'uppercase'
                        
                    
                    }
                },
                {
                    props: {variant: 'sectionsub'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        
                    }
                },
                {
                    props: {variant: 'sectionbody'},
                    style:{
                        fontWeight: 'normal',
                        fontSize: '1rem'
                    
                    }
                },
                {
                    props: {variant: 'thin'},
                    style:{
                        fontWeight: 'light'
                    }
                },
                {
                    props: {variant: 'normal'},
                    style:{
                        fontWeight: 'normal',
                        marginBottom:"2%"
                    }
                },
                {
                    props: {variant: 'bold'},
                    style:{
                        fontWeight: 'bold'
                    }
                },
                {
                    props: {variant: 'bolder'},
                    style:{
                        fontWeight: 'bolder',
                  
                    }
                },
                {
                    props: {color:'text'},
                    style:{
                        color: hometheme.palette.secondary
                    }
                },
                {
                    props: {color:'title'},
                    style:{
                        color: hometheme.palette.secondary
                    }
                },
                {
                    props: {color:'main'},
                    style:{
                        color:hometheme.palette.primary
                    }
                },
                {
                    props:{size:'small'},
                    style:{
                        fontSize: '.8rem'
                    },
                  
                },
                {
                    props:{size:'medium'},
                    style:{
                        fontSize: '1rem'
                    },
                  
                },
                {
                    props:{size:'large'},
                    style:{
                        fontSize: '1.2rem'
                    },
                  
                },
                {
                    props:{size:'xlarge'},
                    style:{
                        fontSize: '2.2rem'
                    },
                    
                }

            ],
            

        },
        MuiButton: {
            variants: [
            {
                props: { variant: 'dashed' },
                style: {
                textTransform: 'none',
                // border: `2px dashed ${blue[500]}`,
                },
            },
            {
                props: { variant: 'dashed', color: 'secondary' },
                style: {
                // border: `4px dashed ${red[500]}`,
                },
            },
            ],
        },
        MuiDivider:{
            styleOverrides :{
                root:({ownerState}) => ({
                    borderBottomWidth: 2 ,
                    borderColor:'black'
              })
            },
        },
        MuiSvgIcon:{
            styleOverrides :{
              root:({ownerState}) => ({
                color: theme.palette.primary.main, 
              })
            },
        }
    },
    
  });
theme = responsiveFontSizes(theme);
