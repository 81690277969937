import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from './components/Layout/ScrollToTop';
import reportWebVitals from './reportWebVitals';



import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
        refetchOnWindowFocus: false, // default: true
        },
  },
});
const headerFade = false;

root.render(
    <BrowserRouter >
        <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ScrollToTop></ScrollToTop>
            {/* <Header fade={headerFade}/> */}
            <App/>
            {/* <Footer /> */}
        </LocalizationProvider>
        <ReactQueryDevtools/>
        </QueryClientProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
