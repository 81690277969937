import {
  getAuth,
  sendPasswordResetEmail, signInWithEmailAndPassword, signOut,
  updateEmail, updatePassword
} from "firebase/auth";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "../../utils/fb";
import { useLocalStorage } from "./useLocalStorage";

import { doc, getDoc, getFirestore } from "firebase/firestore";
import { create, deleteOne, modify } from "../../api/api";

const AuthContext = createContext();
const auth = getAuth(app());


const db = getFirestore(app());

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [isAdmin, setIsAdmin] = useLocalStorage('isAdmin',null);
  const navigate = useNavigate();
  // call this function when you want to authenticate the user
  const login = async (data) => {
    const {username,password} = data;
    signInWithEmailAndPassword(auth,username,password)
    .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        const docRef = doc(db, "user", user.uid);
        setUser(user);
        getDoc(docRef).then(userobj =>{
          let admin = userobj.data()?.admin
          setIsAdmin(admin);
          if(admin){
            navigate("/admin");
          }else{
            navigate("/admin/event");
          }
        });
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
        alert("Invalid username or password");
    });
  };
  // call this function to sign out logged in user
  const logout = () => {
    signOut(auth).then(() => {
        setUser(null);
        navigate("/admin/login", { replace: true });
      // Sign-out successful.
    }).catch((error) => {
      console.error(error);
      // An error happened.
    });
  };

  const resetPass = (emailAddress) =>{
    return sendPasswordResetEmail(auth,emailAddress).then(()=>{
      return "success";
    }).catch((error)=>{
      console.error('Unable to send password email. Error: ',error);
      return "error";
    })
  }

  /** Manage Users */
  const createUser = (newUser) =>{
    
  //   createUserWithEmailAndPassword(auth, email, password)
  // .then((userCredential) => {
  //   // Signed up 
  //   //TODO:add user to user db
  //   const user = userCredential.user;
    create('admin/user',newUser).then(()=>{
  
      alert('New user created');
    //Todo: send email to all admins that a new user was created.

    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    alert('Unable to create new user: '+errorMessage);
    console.error('Unable to create new user: ',errorMessage);
    // ..
  });

  }

  const updateAdmin = (user,admin) =>{
    create('admin/user',{id:user.uid,admin:admin}).then(()=>{
    
      alert('user type updated');
    });
  }

  const updateUserProfile = (user,updates) =>{

    modify('admin/user',user.uid,updates).then(() => {
      // Profile updated!
      console.debug('profile updated');
      alert('User profile updated.');
      return 'success';
      // ...
    }).catch((error) => {
      // An error occurred
      console.error('Error trying to update profile: ',error );
      // ...
    });
    
  }

  const updateUsername = (user,emailAddress)=>{
    //update a user's username which is their email address
    updateEmail(user, emailAddress).then(() => {
      console.debug('User email updated.');
      alert('User email updated.');
      return "success";
      // Email updated!
      // ...
    }).catch((error) => {
      alert('An error occurred trying to update the email: \n',error);
      console.error('Error trying to update email: ',error);
      // An error occurred
      // ...
    });
    
  }

  const updateUserPassword = (user,newPassword) =>{
    updatePassword(user, newPassword).then(() => {
      console.debug('User password updated.');
      alert('User password updated.');
      return "success";
      // Update successful.
    }).catch((error) => {
      alert('An error occurred trying to update the password: \n',error);
      console.error('Error trying to update password: ',error);
      // An error ocurred
      // ...
    });
  }

  const removeUser = (user) =>{
    deleteOne('admin/user',user.uid).then(() => {
      alert('User '+user.email+' Deleted');
      // User dele ted.
    }).catch((error) => {
      alert('Unable to delete '+ user.email);
      console.error('Unable to delete '+ error);
      // An error ocurred
      // ...
    });
    
  }
  const value = useMemo(
    () => ({

      user,
      login,
      logout,
      resetPass,
      isAdmin,
      createUser,
      updateUsername,
      updateUserPassword,
      updateUserProfile,
      updateAdmin,
      removeUser
    }),
    [user,isAdmin]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};