import { Add, Delete, Edit } from "@mui/icons-material";
import { AppBar, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, styled, tableCellClasses } from "@mui/material";
import { getAuth } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { getAll } from "../../api/api";
import woglogo from '../../assets/woglogo.png';
import { CustomSuccessAlert } from "../../reusables/CustomAlert";
import { app } from "../../utils/fb";
import { theme } from "../CMS.theme";
import { useAuth } from "./useAuth";
const db = getFirestore(app());
const auth = getAuth(app());

export const ManageUsers = () => {
    const {user,createUser,updateUserProfile,updateUsername,updateUserPassword,updateAdmin,removeUser} = useAuth();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const fullName = `${firstName} ${lastName}`;
    const [admin, setAdmin] = useState(false);
    const [addUser,setAddUser] = useState(false);
    const [disabled,setdisabled ] = useState(true);
    const [status,setstatus] = useState();
    const [editTitle,setEditTitle] = useState("");
    const [headerHeight,setheaderheight] = useState();
    const [userList,setUserList] = useState();

    const [selected,setSelected] = useState();
    // const [authUsers,setAuthUsers] = useState();
    const handleCheck = (event) => {
        setAdmin(event.target.checked);
      };

    const handleEdit = (userRow) =>{
        console.debug(userRow);
        setUsername(userRow.email);
        setAdmin(userRow.admin);
        setFirstName(userRow.displayName?.split(" ")[0]?userRow.displayName?.split(" ")[0]:"");
        setLastName(userRow.displayName?.split(" ")[1]?userRow.displayName?.split(" ")[1]:"");
        setEditTitle("Update");
        setSelected(userRow);
        setAddUser(true);
    }


    const handleClickSubmit = (object) =>{
        let {username,password,admin,updates} = object;

        if(editTitle.toLocaleLowerCase()=='add'){
            let userBody = {
                email:username,
                password:password,
                admin:admin,
                displayName:fullName
            }
            createUser(userBody);
            setAddUser(false);
            window.location.reload();
        }else{
            // let {admin,...user} = selected;
            
            if(selected.email != username){
                updates = Object.assign(updates,{email:username});
                // updateUsername(user,username);
            }
            if(password != ""){
                 updates = Object.assign(updates,{password:password});
                // updateUserPassword(user,password);
            }
            if(selected.admin != admin){
                updates = Object.assign(updates,{admin:admin});
                // updateAdmin(user,admin);
            }
            updateUserProfile(selected,updates);
            setAddUser(false);
            window.location.reload();
        }   
                           
    }

 const handleAdd = () =>{
        setUsername("");
        setAdmin(false);
        setFirstName("");
        setLastName("");

        setSelected();
        setAddUser(true);
        setEditTitle('Add');
    }

    useEffect(()=>{
        setheaderheight(document.getElementById('app-header').offsetHeight);
        getDocs(collection(db, "user")).then(async(doc)=> {
            let uids;
       
            await doc.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                if(!uids){
                    uids = `${doc.id}`;
                }else{
                    uids = `${uids},${doc.id}`;
                }
               
                console.log(doc.id, " => ", doc.data());
              
                
            });
            getAll('admin/user?uid='+uids).then(results=>{
                console.log(results);
             
                let finalResults = [];
                results.data.forEach((result)=>{
                    let existing = doc.docs.find((element)=> element.id == result.uid);
                    if(existing)                    
                        finalResults.push(Object.assign(result,existing.data()));
                })
                setUserList(finalResults);
            })
        });
       
       
    },[]);
    
    useEffect(()=>{
        if(editTitle=='Add'){
            if(username!="" && password!=""){
                let email = validateEmail(username);
                let pass = validatePass(password);
                if(email && pass){
                    setdisabled(false);
                }else{
                    setdisabled(true);
                }
            }
            else{
                setdisabled(true);
            }
        }else{
            if(username!=""){
                setdisabled(false);
            }
        }

    },[username,password,admin,firstName,lastName])
   
    const validateEmail = (username) => {
        // let username = e.target.value;
        // setUsername(username)
        // setdisabled(false);
        if (!username) {
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
            // setdisabled(true);
            return false;
            
            // validated 
        }else{
            return true;
        }
    }
    const validatePass = (password) => {
        // let password = e.target.value;
        // setPassword(password)
        // setdisabled(false);
        if (!username) {
            return false;
        } else{
            return true;
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          fontFamily:'League Spartan',
          fontSize:24
        },
        [`&.${tableCellClasses.body}`]: {
            fontFamily:'League Spartan',
          fontSize: 20,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
          fontFamily:'League Spartan',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const handleDelete=(e,userRow)=>{
        removeUser(userRow);
        setAddUser(false);
        window.location.reload();
    }
      const [anchorEl, setAnchorEl] = useState(null);
      const [open, setOpen] = useState(false);
      const [placement, setPlacement] = useState();
    
      const handleClickDelete = (newPlacement,row) => (event) => {
        setSelected(row);
        // setAnchorEl(event.currentTarget);
        setOpen(event.currentTarget);
        // setPlacement(newPlacement);
      };
    
    return (
        <ThemeProvider theme={theme}>
            
            <AppBar 
            id='app-header'
            variant='login'
            position="fixed" 
            color="inherit"
            >   
            <Grid 
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                }}
            >
              <Grid 
                style={{
                    position:'fixed',
                    paddingLeft:70,
                }}
              >
                {(woglogo != "") ?  
                  <img style={{width:'18rem', objectFit:'contain'}}src={woglogo} onClick={()=>window.open('/admin/login')} />
                :
                  <div style ={{height:"10vh"}}></div>}
              </Grid>
              <Grid
                style={{
                    height:'15vh',
                    width:'100vw',
                    alignContent:'center'
                }}
              >
                <Typography 
                    variant="h3"
                    color={'white'}
                    style={{
                        textAlign:'center',
                        alignSelf:'center'
                    }}
                >Admin Dashboard</Typography>
              </Grid>
            </Grid>
             </AppBar>
            <Grid 
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    marginTop:`${headerHeight}px`,
                    padding:'5%',
                }}
            >
                 <Popover 
                    anchorReference="anchorPosition"
                    open={open}
                    onClose={()=>setOpen(false)}
                    anchorPosition={{ top: 200, left: 800 }}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    >
                        <Paper 
                            sx={{display:'flex',flexDirection:'column',alignItems:'center',p:4}}
                        >
                            <Typography variant="h3" >Delete User?</Typography>
                            <br></br>
                           <Typography variant="h6">Are you Sure you want to delete user:</Typography>
                           <Typography variant="h6" style={{fontWeight:'bold'}}>{selected?.email}?</Typography>
                           <ButtonGroup>
                            <Button 
                                style={{
                                    marginRight:'2vh'
                                }}
                                
                                onClick={(e)=>{
                                   handleDelete(e,selected);   
                                    }}>Yes</Button>
                                <Button onClick={()=>setOpen(false)}>Cancel</Button>
                           </ButtonGroup>

                        </Paper>
                       
                    </Popover>
               {status == "success" && <CustomSuccessAlert message={"An email has been sent to reset your password."}/>}
               {status =="error" && <CustomSuccessAlert message={"Sorry, we are unable to send password email."}/>}
                {!addUser &&
                <Grid
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                }}
                >
                    <Typography variant='eventtitle'>Manage Users</Typography>
                    <Grid
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        width:'fit-content',
                        marginTop:'5vh',
                    }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650,}} aria-label="simple table">
                                <TableHead>
                                <TableRow sx={{fontFamily:'League Spartan'}} >
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell align="right">Name</StyledTableCell>
                                    <StyledTableCell align="right">User Type</StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {userList?.map((row) => (
                                    <StyledTableRow
                                    key={row.uid}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <StyledTableCell component="th" scope="row">
                                        {row.email}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.providerData[0].displayName}</StyledTableCell>
                                    <StyledTableCell align="right">{row.admin?'administrator':'user'}</StyledTableCell>
                                    {(user.uid != row.uid )?
                                    <ButtonGroup>
                                    <IconButton onClick={()=>{handleEdit(row);}}>
                                        <Edit />
                                    </IconButton>
                                     {!["l90R7Wlsw3S9arfQP2PkZwFUpVw2","lkSfRECoVMQe7DguZYTCZP9YdWz1","o2AHtQmOTtUA2arDN7jVYaS0dBk1"].includes(row?.uid) &&<IconButton 
                                     id={row}
                                     onClick={handleClickDelete('bottom',row)}>
                                        <Delete />
                                    </IconButton>}
                                    </ButtonGroup>
                                    :
                                    <p>You</p>}
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <IconButton 
                        onClick={()=>{handleAdd()}}
                    > <Add />
                        Add User
                    </IconButton>
                </Grid>}

                {addUser && 
                <form 
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                    >
                    <Grid
                     style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                    >
                        <Typography variant='eventtitle'>{editTitle} New User</Typography>
                        <Typography variant='body1'>Enter an email and password for the new user</Typography>
                        <Grid
                            sx={{
                                width:'fit-content',
                                marginTop:'5vh',
                                display:'flex',
                                flexDirection:'column'
                            }}
                        >
                        <label htmlFor="username">First Name: </label>
                        <input
                            id="username"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <label htmlFor="username">Last Name: </label>
                        <input
                            id="username"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <label htmlFor="username">Email Address: </label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                         <label htmlFor="username">Password: </label>
                        <input
                            id="password"
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControl>
                        <FormControlLabel 
                        disabled={["lkSfRECoVMQe7DguZYTCZP9YdWz1","o2AHtQmOTtUA2arDN7jVYaS0dBk1"].includes(selected?.uid)}
                        control={<Checkbox
                          checked={admin}
                          onChange={handleCheck} />} label="Administrator" />
                        </FormControl>
                        
                        </Grid>
                        <Grid
                            style={{
                                display:'flex',
                                flexDirection:'row',
                            }}
                        >
                            <Button 
                            style={{
                                marginRight:'2vh'
                            }}
                            disabled={disabled}
                            onClick={()=>{
                                handleClickSubmit({username,password,admin,updates:{displayName:fullName}});     
                                }}>Submit</Button>
                            <Button onClick={()=>setAddUser(false)}>Cancel</Button>
                            
                        </Grid>
                       
                    </Grid>
                </form>}
            </Grid>
        </ThemeProvider>
  );
};