import { Circle, Facebook, YouTube } from '@mui/icons-material';
import { Grid, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import handHolding from '../../assets/hands_and_bible.jpeg';
import { theme } from '../Home/Home.theme';
export function Connect() {
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <ThemeProvider theme={theme}>
            <Grid  
                variant="connectmain">
                <Grid 
                    variant="connectsectionContent"
                    
                >
                    <Grid
                        variant="wordsContainer"
                    >
                    <Typography
                    sx={{
                        color:theme.palette.primary.main,
                        [theme.breakpoints.down('sm')]: {
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center'
                        },  
                    }}
                    variant="h2">
                    Casual 
                    <Circle style={{
                        margin:5,
                        color:theme.palette.primary.main,
                        fontSize:'.6rem'
                    }}/> 
                    Friendly 
                        <Circle 
                        style={{
                        margin:5,
                        color:theme.palette.primary.main,
                        fontSize:'.6rem'
                    }}/> 
                    Welcoming 
                    </Typography> 
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Typography variant="h3">
                        Live worship music
                    </Typography> 
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Typography variant="h3">
                        Inspirational teaching 
                    </Typography> 
                    </Grid>

                    <Grid
                        sx={{
                            display:'flex',
                            [theme.breakpoints.down('sm')]: {
                                marginTop:'2rem'
                            },
                        }}
                        >
                        <Grid sx={{
                            width: 600,
                            height: 600,
                            borderRadius: 600/2,
                            backgroundColor:'rgba(102,20,20,0.5)',
                            [theme.breakpoints.down('sm')]: {
                                width:300,
                                height:300,
                                borderRadius: 300/2,
                            },
                        }}>
                            <img 
                            style={{
                                width: matches?300:'600px',
                                height: matches?300:'600px',
                                borderRadius:matches?300/2: 600/2,
                                opacity:'0.5',
                                
                            }}
                            src={handHolding}
                            ></img>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <br></br> 
                <Grid 
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        height:'40vh',
                        width:'100vw',
                        justifyContent:'center',
                        alignItems:'center',
                        textAlign:'center',
                        backgroundColor:theme.palette.primary.main,
                    }}
                >
                    <Typography 
                        style={{
                            width:'50%',
                            textAlign:'center',
                            color:'white'
                        }}
                        variant="h2">
                            Follow Us On Social Media
                    </Typography> 
                    <br></br>
                    <br></br> 
                    <br></br>
                    <Grid 
                    style={{
                        display:'flex',
                        width:'30%',
                        justifyContent:'space-evenly'
                    }}
                    >
                        <YouTube variant="connecticon" onClick={()=>window.open("https://www.youtube.com/@whiteoakgrovembc8556/featured")}/>
                        <Facebook variant="connecticon" onClick={()=>window.open('https://www.facebook.com/WOGMBCLive')}></Facebook>
                    </Grid>
                </Grid>
            </Grid>
            
        </ThemeProvider>
    )
}