import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../Home/Home.theme';

import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from "dayjs/plugin/utc";
import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { getAll } from '../../api/api';
import { EventContent } from './EventContent';

export function Events() {
  const matches = useMediaQuery(theme.breakpoints.down('md')); //For responsive design
  const [isLoading, setisLoading] = useState(true);
  const [calevent,setcalEvent] = useState([]); 
  const [limit,setLimit] = useState(10);
  const [page, setPage] = React.useState(1);
  const [maxDate,setMaxDate] = useState();
  const daterange = 30; //in days

  const now = dayjs();
  dayjs.extend(utc);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
 
  //Filter list of events to be only 30 days the day of or after the given date.
  const filterEvents = (list, date) =>{
      return list.filter(ev => {
        let timestampEv;
       if(ev.date instanceof Timestamp){
         timestampEv = ev.date;
       }else{
         timestampEv = new Timestamp(ev.date._seconds,ev.date._nanoseconds);
       }
        
        let jsEv = timestampEv.toDate();
        let evDate = dayjs.utc(jsEv);
      
        let daysLater = date.add(daterange,'day');
        return evDate.isSameOrAfter(date,'date') &&  evDate.isSameOrBefore(daysLater);
    
    });
  }
  const [selectedDate, setSelectedDate] = useState(now);
  const [lastListedDate ,setLastListedDate] = useState(dayjs());
  const [firstListedDate ,setFirstListedDate] = useState(dayjs());
  useEffect(()=>{
    const lastListedDate = selectedDate.add(daterange,'day');
    setFirstListedDate(selectedDate);
    getAll('event?start='+selectedDate.format('MMM D YYYY, h:mm:ss A')+'&end='+lastListedDate.format('MMM D YYYY, h:mm:ss A')).then(data=>{
        let eventsList = [];
        let filtered ;
        if(data && Object.keys(data).includes('data')){
          if(data?.data?.length>0){
            if(data.data.length>0){
              data.data.map(obj=>{
                console.debug(obj);
                  obj.data.displayDates.map(date=>{
                    //Set correct time
                    let timestampEv = new Timestamp(obj.date._seconds, obj.date._nanoseconds);
                    let jsEv = timestampEv.toDate();
                    let dayjsEv = dayjs.utc(jsEv);
                    let hour = dayjsEv.hour();
                    let minute = dayjsEv.minute();
                    let second = dayjsEv.second();

                    let timeStampDate = dayjs.utc(new Timestamp(date._seconds,date._nanoseconds).toDate());
                    timeStampDate = timeStampDate.set('hour',hour).set('minute',minute).set('second',second);
                    let correctedDate = Timestamp.fromDate(timeStampDate.toDate());
                    let newobj = {...obj,...{date:correctedDate}};
                    eventsList.push(newobj);
                  })
                
              })
              filtered= filterEvents(eventsList,selectedDate);
              let sortedfiltered = filtered.sort((a,b)=>{
                const Adate = a.date;
                const Bdate = b.date;
                if(dayjs.utc(Adate.toDate()).isBefore(dayjs.utc(Bdate.toDate()))){
                  return -1;
                }
                if(dayjs.utc(Adate.toDate()).isAfter(dayjs.utc(Bdate.toDate()))){
                  return 1;
                }
                return 0;
              });
              setFilteredEvents(sortedfiltered);
            }else{
              eventsList = data.data;
              filtered= filterEvents(eventsList,selectedDate);
              setFilteredEvents(filtered);
            }
          }
        }
        setcalEvent(eventsList);
        try{
          //Sets the max date that can be selected on the date picker to the latest date of any events. 
          setMaxDate(dayjs(new Timestamp(data.data[data.data.length-1].date._seconds,data.data[data.data.length-1].date._nanoseconds).toDate()));
        }catch (e) {
          console.error('Unable to parse date for event: ',e);
          setMaxDate(null);
        }
   
        return filtered;
    }).then((data)=>{
        let amount = data.length;
        // setSelectedDate(now);     
        let factor = (limit > amount)? amount: limit;
        try{   
          if(amount >0){
            setLastListedDate(lastListedDate);
          }
        }catch (e) {
          console.error('Unable to parse date for event: ',e);
          setLastListedDate(null);
        }
        setisLoading(false);
    });
  },[selectedDate]);

  //DATE PICKER
  

  const todayClick = () =>{
    handleSelectedDate(now);
  }
  const [filteredEvents, setFilteredEvents] = useState([]);
  
  const handleSelectedDate = (newDate) => {
    setSelectedDate(newDate);
    const lastListedDate = newDate.add(daterange,'day');
 
    setPage(1);
    setLastListedDate(lastListedDate);
    // setLastDateOnPage(1,filtered);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFirstListedDate(prev => prev.add(limit,'day'));
  };


  return (
    <ThemeProvider theme={theme}>
      {(isLoading) ? 
      <Grid
        display="flex" 
        flexDirection={"column"} 
        alignItems={"center"} 
        minWidth={"100%"} 
        minHeight={"100%"} 
        justifyContent={"center"}>
          <CircularProgress style={{color:theme.palette.primary.main}} />
      </Grid>
      :
      //Whole page not including border or header. Appears under header      

      <Grid  
        variant="eventContainer"
       >
        <Grid variant="eventTop">
        {theme.breakpoints.down('md')?
          <Grid style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems:'flex-end',
          }}>
            
            <DatePicker 
              slotProps={{
              layout: {
                sx: {
                  padding:1
                }
              }}}
              minDate={new dayjs()}
              // maxDate={maxDate}
              // defaultValue={dayjs()}
              value={selectedDate} 
              onChange={handleSelectedDate}/>
            
            <Button 
              style={{ height:'6vh',width:'100%'}}
              value={now}
              onClick={todayClick} >Today</Button>
          </Grid>
        :
          <Grid style={{display: "flex",
            flexDirection: matches?"column":"row",
            justifyContent: "space-between",
            width: matches?"40vw": "21vw",
            alignItems:'flex-end',
            }}>
              <Button 
                style={{ height:'6vh',width:'9vw'}}
                value={now}
                onClick={todayClick} >Today</Button>
              <DatePicker 
                slotProps={{
                layout: {
                  sx: {
                  // height:'8vh',
                  padding:1
                  }
                }}}
                minDate={new dayjs()}
                maxDate={maxDate}
                // defaultValue={dayjs()}
                value={selectedDate} 
                onChange={(newValue)=>setSelectedDate(newValue)}
                onAccept={handleSelectedDate}
                />
          </Grid>}
            <Typography style={{marginTop:10,alignSelf:'center',fontWeight:'normal'}}>{firstListedDate?firstListedDate.format('MMMM DD'):""} - {lastListedDate?lastListedDate.format('MMMM DD'):""} </Typography>
            <br/>
            <br/>
            <br/>
            <Typography style={{fontWeight:'light',fontSize:'1.5rem',textAlign:'center'}}>{firstListedDate?firstListedDate.format('MMMM YYYY'):""}</Typography>
            <br/>
            <br/>
        </Grid>
        <Grid 
            variant="sectionContent"
            style={
              { 
                alignItems:'center'
              }}
        >
          
            {(limit > 0
            ? filteredEvents.slice((page-1) * limit, (page-1) * limit + limit)
            : filteredEvents) 
            .map(section => {
              return(    
                  <Grid style={
                    {
                      display:'flex',
                      flexDirection:'column',
                      width:matches?'100%':'70%',
                      // alignContent:'center'
                    }
                  }>
                  <EventContent key={section.id} section={section}/>
                  <br/><br/>
                  </Grid>
                );
            })}
            <Pagination 
                size='large'
                count={Math.ceil(filteredEvents.length/limit)}
                page={page}
                onChange={handleChangePage}
            >
            </Pagination>
            
        </Grid>
      </Grid>  
      }
      
     </ThemeProvider>
  );
}