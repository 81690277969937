import React, { } from 'react';
import { Grid,Typography,Link, IconButton,} from '@mui/material';
import { GrMapLocation } from "react-icons/gr";
import { PiHandsPraying } from "react-icons/pi";
import { FaHandHoldingHeart } from "react-icons/fa";

export default function LinksSection({planvisit,matches}){
    return (
      <Grid 
      style={{
        backgroundColor:'rgba(102, 20, 20,1)', 
        marginTop:'3rem',
        width:'100%',
        
        }}>
        <Grid variant='linksSection' 
        style={{
          backgroundImage:`url(${planvisit})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          display:matches?'flex':"",
          flexDirection:matches?"column":"row",
          alignItems:matches?'center':"",
          }}
          >
          <Grid  variant='linksSectionContent' 
          >
            <IconButton component={Link} href="/connect/visit"  
                style={matches?{height:'50%'}:{width:'25%',height:'30%'}}>
              <GrMapLocation style={{width:'100%',height:'100%',color:'white'}} />
            </IconButton>
            <Typography underline='none' variant='link' component={Link} href="/connect/visit">
              Plan A Visit
            </Typography>
          </Grid>
          <Grid 
          variant='linksSectionContent'
          >
            <IconButton component={Link} href="/connect/prayer"  
                style={matches?{height:'50%'}:{width:'25%',height:'30%'}}>
              <PiHandsPraying
                style={{width:'100%',height:'100%',color:'white'}} />
            </IconButton>
            <Typography component={Link} underline='none' href="/connect/prayer" variant='link' >
              Request Prayer
              </Typography>
          </Grid>
          <Grid  variant='linksSectionContent' 
          >
            <IconButton component={Link} href="/ministry"  
                style={matches?{height:'50%'}:{width:'25%',height:'30%'}}>
              <FaHandHoldingHeart
                style={{width:'100%',height:'100%',color:'white'}} />
            </IconButton>
            <Typography underline='none'variant='link'  component={Link} href="/ministry">Join A Ministry</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }