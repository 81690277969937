import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";


const EditContext = createContext();

export const EditProvider = ({ children }) => {

    
  let {pathname} = useLocation();
  const page =  pathname.substring(7);
  
  const [selected, setSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [enableSave,setenablesave] = useState(false);
  const [enableReset,setenablereset] = useState(true);
  const [enablePublish,setenablepublish] = useState(false);
  const [enableRevert,setenablerevert] = useState(true);

  const handleValidation = useCallback((e) =>{

    if(e.target){
        // e.preventDefault();
        let isRequired = e.target.required;
        let value = e.target.value;
        if(isRequired && (value == "")){
            setenablesave(false);
        
        }else{
            setenablesave(true);
    
        }
    }else{
        setenablesave(true);
    }
    
  },[]);

  const handleClick = (e,index,section) =>{
    let id = section.id;
    // let itemSelected = list?list.find((el,ind) => ind == index):{};
    setSelectedItem(section);
    // e.preventDefault();
    const component_id = e.currentTarget.id;
    // setTimeout(()=>{
      setSelected({id:id,index:index,component:component_id,page:page});
    // },200);
  };
  

  const handleClickAddSection = (e,list,dataObject,func) =>{
    e.preventDefault();
    func("text","add",{e,list,page,dataObject});
  }

  const value = useMemo(
    () => ({
        
        selected,
        setSelected,
        selectedItem,
        setSelectedItem,
        handleClick,
        handleClickAddSection,
        // handleChange,
        enableSave,
        setenablesave,
        enableReset,
        setenablereset,
        enablePublish,
        setenablepublish,
        handleValidation,
        enableRevert,
        setenablerevert
     
     
    }),
    [selected,enableSave,enableReset,enableRevert,enablePublish,selectedItem]
  );
  return <EditContext.Provider value={value}>{children}</EditContext.Provider>;
};

export const useEdit = () => {
  return useContext(EditContext);
};