import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue,red,orange } from '@mui/material/colors';
import {theme as hometheme } from '../Home/Home.theme';

let theme = createTheme(hometheme);
theme = responsiveFontSizes(theme);
theme = createTheme(theme,{ 
    components: {
        MuiGrid:{
            variants: [
                {
                    props: {variant:'heroGrid'},
                    style: {
                        position:'relative',
                        display:"flex", 
                        flex:"1",
                        flexDirection:"row",
                        alignItems:"flex-end",
                        justifyContent:"center", 
                        backgroundColor:'black',
                        height:'58vh',
   
                        // border: `4px dashed ${blue[500]}`,
                    }
                },
                {
                    props: {variant:'smallheroGrid'},
                    style: {
                        // border: `4px dashed ${blue[500]}`,
                        position:'relative',
                        display:"flex", 
                        flex:"1",
                        width:'100%',
                        flexDirection:"row",
                        alignItems:"flex-end",
                        justifyContent:"center", 
                        backgroundColor:'black',
                       
                       
                    }
                },
                {
                    props: { variant: 'titleGrid'},
                    style: {
                       
                        display:"flex",
                        // marginBottom:"10vh",
                        top:'45%',
                        maxWidth:"80%",
                        position:'absolute',
                        // border: `4px dashed ${orange[500]}`,
                    }
                },
    
               
            ]
        },
        MuiTypography:{
            styleOverrides :{
                root:({ownerState}) => ({
                    fontFamily: 'League Spartan',
                }),
                h2:{
                    
                    fontSize: "5rem",
                    [theme.breakpoints.up('lg')]: {
                  
                      fontSize: "5rem"
                    },
                    [theme.breakpoints.between('sm', 'lg')]: {
                        fontSize: "3rem"
                      },
                    [theme.breakpoints.between('xs', 'sm')]: {
                        fontSize: "2rem"
                    },

                    color: 'white',
                    fontWeight: 'normal'
                },
             
            },
        },
    },

    
  });

export {theme};