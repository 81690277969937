export const cmsCollections = [
    {
        name:'hero',
        path:""
    },
    // {
    //     name: 'home',
    //     path: '/'
    // },
    {
        name: 'corebeliefs',
        path: '/about'
    },
    {
        name: 'mission',
        path: '/about'
    },
    {
        name: 'aboutwho',
        path: '/about/who'
    },
    {
        name: 'aboutwhere',
        path: '/about/where'
    },
    {
        name: 'ministry',
        path: '/ministry'
    },
    {
        name: 'event',
        path: '/event'
    },
    {
        name: 'recurrence',
        path: '/event'
    },
    {
        name: 'announcement',
        path: '/announcement'
    },

];