import "@fontsource/league-spartan";
import "@fontsource/league-spartan/100.css";
import "@fontsource/league-spartan/700.css"
import "@fontsource/league-spartan/600.css"
import "@fontsource/league-spartan/900.css"
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {theme as hometheme} from '../Home/Home.theme';

export const linkStyle = {
  textDecoration: 'none',
  
  // textTransform:'uppercase',
  
  // fontWeight:'lighter',
  fontFamily:'League Spartan',
  color: 'white',
  // backgroundColor:'rgba(0, 0, 0,0.8)',
  display:'flex',
  width:'fit-content',
}
export const linkStyleWhite = {
  textDecoration: 'none',
  textTransform:'uppercase',
  fontFamily:'League Spartan',
  fontSize:'1rem',
  color: 'white',
  alignItems:'center',
  display:'flex',
  width:'fit-content',
  margin:'20px',
  // flex: '0 1 calc(20% - 20px)',
  flex:'0 1 20px',


  //
  // margin: 20px;
  // display: flex;
  // flex: 0 1 calc(25% - 30px);
  // background: #666666;
  // box-sizing: border-box;
  // height: 50px;
}
export const linkStyleSelected = {
  textDecoration: 'none',
  textTransform:'uppercase',
  fontFamily:'League Spartan',
  fontSize:'1rem',
  color: hometheme.palette.info.main,
  alignItems:'center',
  
  display:'flex',
  width:'fit-content',
  margin:'20px',
  // flex: '0 1 calc(20% - 20px)',
  flex:'0 1 20px',
}

export const iconStyle = {
  alignSelf:'center',
  fontSize:'1rem',
  // fontWeight:'bold',
}

export let theme = createTheme(
    
    {
    
  
    components:{
     
      MuiGrid:{
        variants:[
          {
            props:{variant:'bar'},
            style:{
              width:"100%",
              backgroundColor: hometheme.palette.primary,
              opacity:"0"
            }
          },

          {
            props:{ variant:'header'},
            style:{
              display:'flex',
              justifyContent:'center',
              // paddingInline:'15vw',
              width:'100%',
              // border:'2px dashed red',

            }
          },
          {
            props:{ variant:'headerSmall'},
            style:{
              display:'flex',
              justifyContent:'space-between',
              paddingInline:'7vw',
              width:'100%',
              height:'10vh',
            }
          },
          {
            props:{ variant:'headerlogo'},
            style:{
              display:'flex',
             
              // width:'15%',
              flex:'0.1 1 11.2%',
              // border:'2px solid orange'
            }
          },
          {
            props:{ variant:'logoSmall'},
            style:{
              display:'flex',
              flex:9,
              width:'fit-content',
              // border:'2px solid yellow'
            }
          },
          {
            props:{ variant:'headermenucontainer'},
            style:{
              display: 'flex',
              // flex:3,
              alignSelf:'center',
              justifyContent:'flex-end',
              height:'100%',
              alignItems:'center',
              width:'50%',
              // border:'2px dashed blue'

            }
          },
          {
            props:{ variant:'headermenu'},
            style:{
                
                display:'flex', 
                flexWrap:'wrap',
                // width:'100%',
                justifyItems:'flex-end',
                flexDirection:'row',
                justifyContent:'space-between',
                // border:'3px dashed purple',

            }
          },

        ]
      },
      MuiList:{
        variants:[
          {
            props:{variant: 'dropdownBackground'},
            style:{
              backgroundColor: 'rgba(0, 0, 0,0.8)',
             
            }
          }
        ]
      },
      MuiListItem:{
        styleOverrides:{
          root:{
            '&:hover':{
              backgroundColor: 'rgba(30, 30, 30,0.8)',
            }
          },
        }
      },
      MuiListItemText:{
       
        styleOverrides:{
          root:{
            textDecoration: 'none',
          
          },
          primary:{
            fontFamily:'League Spartan',
            fontSize:'1.1rem',
            color: 'white',
           
          },
        },
        variants:[
          {
            props:{variant: 'menuText'},
            style:{
              color: 'red'
            }
          }
        ]
      },
      MuiMenu:{
        styleOverrides:{
          root:{
            
            zIndex:'1000',height:'90%',
            '& .MuiMenu-paper': {
              backgroundColor: 'rgba(0, 0, 0,0.8)',
            }
          }
        },
      },
      MuiMenuItem:{
        styleOverrides:{
          root:{
            '&:hover':{
              backgroundColor: 'rgba(30, 30, 30,0.8)',
            }
          }
        }
      }     
    },
   
  });

 theme = responsiveFontSizes(theme);
 
