import { Avatar, Grid, Paper, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import { theme } from '../Home/Home.theme';

import React from 'react';

export function MinistrySection({section,leftmargin,rightmargin}) {
    

  return (
    <ThemeProvider theme={theme}>   
        <Paper elevation={5}  
        key={`Paper_${section.id}`}
        sx={{
            display:'flex',
            marginLeft:`${leftmargin}`,
            marginRight:`${rightmargin}`,
            p:3,
          
        }}> 
            {section.image 
            &&
            <Grid key={`Grid_${section.id}_0`} style={{ display:'flex', flexDirection:'row'}}>
                <SvgIcon 
                    key={`SvgIcon_${section.id}`}
                    sx={{fontSize:'6rem'}}
                >
                    <svg key={`Svg_${section.id}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                        <image 
                        key={`image_${section.id}`}
                        style={{
                            height:'100%',
                            width:'100%',
                        }}
                        href={section.image}>

                        </image>
                    </svg>
                </SvgIcon>
                <Grid key={`Grid_${section.id}_1`} style={{ display:'flex',width:'2rem'}}></Grid>
            </Grid>
            }
            
            <Grid 
                key={`Grid_${section.id}_2`}
                variant="ministrySectionContent">
                <Typography key={`type_${section.id}_0`} variant="h4" style={{fontWeight:'bold',color:theme.palette.primary.main}}>{section.title}</Typography>
                {section.description &&
                    <Typography key={`type_${section.id}_1`} variant="body1" sx={{fontSize:'1.3rem',marginBottom:'1rem'}}>{section.description}</Typography>
                    
                }
                {(section.contact.length>0) 
                &&
                
                <Grid key={`Grid_${section.id}_3`}  variant="ministryContactGrid">
                    {section.contact.map((contact,index)=>{
                        return(
                            <Grid
                            key={`contact_${index}`}
                                variant="ministryContactGrid"
                            >
                                {contact.image &&
                                    <div key={`div_contact_${index}`}>
                                        <Avatar key={`avatar_contact_${index}`} src={contact.image}></Avatar>
                                        <Grid key={`grid_contact_${index}_0`} style={{ display:'flex',width:'1rem'}}></Grid>
                                    </div>
                                }
                                
                                <Grid 
                                key={`Grid_contact_${index}_0`}
                                    variant="ministrySectionContent">
                                    {contact.name 
                                    &&
                                    <Typography key={`contact_${contact.name}_${index}`} variant="h6" noWrap sx={{fontWeight:'bold',color:theme.palette.primary.main}}>{contact.name}</Typography> 
                                    }  
                                    {contact.title 
                                    &&
                                    <Typography  key={`contact_${contact.title}_${index}`} variant="h7" noWrap sx={{color:theme.palette.primary.main}}>{contact.title}</Typography> 
                                    }   
                                    {contact.email &&
                                    <Typography key={`contact_${contact.email}_${index}`}variant="body1" noWrap>{contact.email}</Typography> 
                                    }
                                    
                                    {contact.phone &&
                                    <Typography key={`contact_${contact.phone}_${index}`} variant="body1" noWrap>{contact.phone}</Typography> 
                                    }
                                    {contact.address &&
                                    <Typography key={`contact_${contact.address}_${index}`} variant="body1" noWrap>{contact.address}</Typography> 
                                    } 
                                </Grid>
                                <Grid key={`Grid_contact_${index}_1`} style={{ display:'flex',width:'2.5rem'}}></Grid>
                            </Grid>
                        )
                    })}
                </Grid>
                }
           </Grid>
            
        </Paper>                  
    </ThemeProvider>
  );
}