import { useMediaQuery } from "@mui/material";
import 'filepond/dist/filepond.min.css';
import { createContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Dashboard } from "./cms/Dashboard/Dashboard.admin";
import { EditProvider } from "./cms/Hooks/useEdit";
import { LoginPage } from "./cms/auth/login";
import { ManageUsers } from "./cms/auth/manageUsers";
import { AuthProvider, useAuth } from "./cms/auth/useAuth";
import { Secret } from "./cms/secret";
import { SessionStorageProvider } from "./cms/utils/useSession";
import { About } from './components/About/About';
import { AboutWhere } from './components/About/AboutWhere';
import { AboutWho } from './components/About/AboutWho';
import { Announcement } from './components/Announcement/Announcement';
import { Connect } from './components/Connect/Connect';
import { Events } from './components/Events/Events';
import Home from './components/Home/Home';
import { theme } from './components/Home/Home.theme';
import { AppLayout } from './components/Layout/AppLayout';
import Layout from './components/Layout/Layout';
import { Ministry } from './components/Ministry/Ministry';
import { Prayer } from "./components/Prayer/Prayer";
import { Visit } from "./components/Visit/Visit";
import './index.css';
import { getFBToken } from './utils/auth';

let didInit = false;
export const SizeContext = createContext();
localStorage.removeItem('changes');
function App(){
  
  const matches = useMediaQuery(theme.breakpoints.down('md')); 
  const [authenticated, setAuthenticated] = useState(false);
  function authenticate (){
    getFBToken()
    .then(resp =>{
      if(resp.status == 202 || resp.status== 200){
        setAuthenticated(true);
        sessionStorage.setItem("ready",true)
      }else{
        sessionStorage.setItem("ready",false)
        setAuthenticated(false);
      }
    })
    .catch(err=>console.error('Unable to retrieve token: ',err));
  }
  useEffect(()=>{
    const minute = 1000 * 60;
    authenticate();
    setInterval(()=>{
      //get a new token every so often before expiration.
      authenticate();
    },minute * 5);
  },[]); 


  const ProtectedRoute = () => {
    const { user } = useAuth();
    return authenticated && user?  <Outlet/> : <Navigate to='/admin/login'/>
  }

  const AdminOnlyRoute = () => {
    const { user,isAdmin } = useAuth();
    return (user && isAdmin)?  <Outlet/> :<Navigate to='/admin/login'/>;
  }
    
  if(!authenticated){
    <div>Loading</div>
  } 
  else{  
    return(
      <SizeContext.Provider value={matches}>
          <AuthProvider>
            <EditProvider>
              <SessionStorageProvider>
                <Routes >
                  <Route 
                    path="/admin"
                    element={<ProtectedRoute />}>
                      <Route path="/admin/ministry" element={<Dashboard authenticated={authenticated}/>}/>
                      <Route path="/admin/event" element={<Dashboard authenticated={authenticated}/>}/>
                      
                  </Route>
                  <Route 
                      path="/admin"
                      element={<AdminOnlyRoute />}>
                      {/* <Route path="/admin/secret" element={<Secret/>}/> */}

                        <Route path="/admin/users" element={<ManageUsers authenticated={authenticated}/>}/>
                        <Route path="/admin" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/about" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/about/who" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/about/where" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/ministry" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/event" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/announcement" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/connect" element={<Dashboard authenticated={authenticated}/>}/>
                        <Route path="/admin/connect/visit" element={<Dashboard authenticated={authenticated}/>}/>
                        {/* <Route path="/admin/connect/join" element={<Dashboard authenticated={authenticated}/>}/> */}
                        <Route path="/admin/connect/prayer" element={<Dashboard authenticated={authenticated}/>}/>
                        
                        <Route path="/admin/*" element={<Secret />} />

                      </Route>
                  <Route element={<AppLayout/>}>
                    <Route path="/admin/login" element={<LoginPage/>}/>
                    <Route path="/" element={<Home authenticated={authenticated} />}/>

                    <Route element={<Layout/>}>
                      <Route path="/about" element={<About/>}/>
                      <Route path="/about/where" element={<AboutWhere />}/>
                      <Route path="/about/who" element={<AboutWho />}/>
                      <Route path="/ministry" element={<Ministry />}/>
                      <Route path="/event" element={<Events />}/>
                      <Route path="/connect" element={<Connect />}/>
                      <Route path="/connect/visit" element={<Visit />}/>
                      {/* <Route path="/connect/join" element={<Join />}/> */}
                      <Route path="/connect/prayer" element={<Prayer />}/>
                      <Route path="/announcement" element={<Announcement/>}/>
                    </Route>   
                  </Route>   
                  <Route path="/*" element={<Secret />} />
                </Routes>
              </SessionStorageProvider>
          </EditProvider>
          </AuthProvider>
          </SizeContext.Provider>
      // </UIContext.Provider>
      // </updateUIContext.Provider>
    )
}
}
export default App;