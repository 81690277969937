
//TODO: pull default visit and prayer hero from connect, if its not there then use a hardcoded
import defaultHero from '../../assets/images/WOG_0811_0365-Enhanced-NR-1 Small.jpeg';
export const setherotitle = async (pathname) => {
    switch(pathname) {
    case '/about':
        return {'title':"What We Believe",'image': 'aboutHero.jpeg'};
        
    case '/about/where':
        return {'title':"Where We've Been",'image': 'aboutwhereHero.jpeg'};
    
    case '/about/who':
        return {'title':"Who We Are",'image': 'aboutwhoHero.jpeg'};
        
    case '/ministry':
        return {'title':"Ministries",'image': 'ministryHero.jpeg'};
        
    case '/event':
        return {'title':"Events",'image': 'eventHero.jpeg'};
        
    case '/connect':
        return {'title':"Connect",'image': 'connectHero.jpeg'};

    case '/announcement':
        return {'title':"Announcements",'image': defaultHero};
        
    case '/connect/visit':
        return {'title':"Plan A Visit",'image': defaultHero};
        
    case '/connect/join':
        return {'title':"Join The Church",'image': defaultHero};
        
    case '/connect/prayer':
        return {'title':"Prayer Requests",'image': defaultHero};
        
    default:
            return {'title':"",'image': ''};
        
    }

}