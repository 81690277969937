import { Grid, ThemeProvider, useMediaQuery } from '@mui/material';

import { theme } from '../Home/Home.theme';

import React, { useEffect, useState } from 'react';
import { getAll } from '../../api/api';

import { LoadingDisplay } from '../../reusables/LoadingDisplay';
import { MinistrySection } from './MinistrySection';
export function Ministry() {
    const [isLoading, setisLoading] = useState(true);
    const [ministry,setMinistry] = useState([]); 
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(()=>{
            getAll('ministry').then(data=>{
                setMinistry(data.data);
                setisLoading(false);
            });
    
    },[]);
  
  return (
    <ThemeProvider theme={theme}>
        {(isLoading) ? 
            <Grid
            // style={{border: `4px dashed ${red[500]}`}}
            display="flex" flexDirection={"column"} alignItems={"center"} minWidth={"100%"} minHeight={"100%"} justifyContent={"center"}>
                <LoadingDisplay />
            </Grid>
              :
        //Whole page not including border or header. Appears under header      
 
            <Grid  
              variant="ministrymain">
                {/* Section for Core Belief Content */}
                <Grid 
                    variant={matches?"ministrysmallsectionContent":"ministrysectionContent"}
                >
                    {ministry.map((section,index)=> {
                        return(         
                            <Grid>
                                <MinistrySection section={section} leftmargin={(index % 2 == 0)? '0':'7%'} rightmargin={(index % 2 == 0)? '7%':'0'}/>
                                <br></br>
                                <br></br>
                            </Grid>     
                        );
                    })}
                </Grid>
                      
             </Grid>
        } 
    </ThemeProvider>
);
}