import { Check, Error } from "@mui/icons-material"
import { Alert, Backdrop } from "@mui/material"

export const CustomSuccessAlert = ({message,onClose}) =>{
    return(
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        >
            <Alert icon={<Check fontSize="inherit" />} severity="success"   
        >
            {message}
            </Alert>
        </Backdrop>
    )
}

export const CustomErrorAlert = ({message,onClose}) =>{
    return(
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        >
            <Alert icon={<Error fontSize="inherit" />} severity="error"
        >
            {message}
            </Alert>
        </Backdrop>
    )
}