
import { copyImage, create, deleteFile, deleteOne, getFile, publishCollection, uploadFile } from "../../api/api";


import { getFirestore } from "firebase/firestore"; //using this for accessing firestore directly from client. bypassing server

import { app } from "../../utils/fb";
import { cmsCollections } from "./AllPages";
const db = getFirestore(app());



/**** IMAGE HANDLERS ****/

const saveImage = async (change) =>{
     return new Promise( async (resolve,reject)=> {
        try{

            let r = await fetch(change.data.blob);
            console.debug('SAVE R: ',r);
            if(r){
                let blob = await r.blob();
                console.debug('BLOB IN SAVE: ',blob);
                if(blob){
                    let resp = await  uploadFile('admin',`${change.location.collection.split('/').join('')}/${change.type}/`,change.data.name,blob);
                    if(resp){
                        let url = await getFile('admin',`${change.location.collection.split('/').join('')}/${change.type}/`,change.data.name);
                        if(url){
                            console.debug('Saved Image: ',url);
                            return resolve(url.data.url);
                        }else{
                            return reject('failed');
                        }
                    }
                }
            }
        }
        catch(err){
            console.error('Error fetching Blob: ',err);
            return reject(err);
        }
    })
    
   
}

const deleteImage = (change) => {
    return new Promise( async(resolve,reject)=>{
        try{
            console.debug('Deleting file ', change.data.name);
            let fileName = change.data.name;
            let resp = await deleteFile('admin',`${change.location.collection.split('/').join('')}/${change.type}/`,fileName);
            if(resp){
                console.debug('Delete Response: ',resp);
                return resolve(resp);
            }
        }catch(error){
            console.error('Error deleting file: ',error);
            return reject(error);
        };
    });
}

/**** FIREBASE OBJECT HANDLERS  ****/

const saveText = (change) =>{
    return new Promise (async(resolve,reject)=>{
        try{
            //Save objects in Firestore
            const {CreatedDate,LastModifiedDate, ...body } = Object.assign({}, change.data);
            //Add or update object fields in firestore
             let resp = await create("admin/"+change.location.collection,body);
             if(!resp.error){
                return resolve(resp);
             }else{
                //Rejected promises come here
                return reject(resp);
             }
        }catch(err){
            return reject(err);
        }
    })
}

const deleteSection = (change) =>{
    return new Promise ( async (resolve,reject)=>{
        try{
            let resp = await deleteOne("admin/"+change.location.collection,change.data.id);

            if(resp.error){
                console.debug(`Unable to remove item ${JSON.stringify(change.data.id)} from database. It must not be a saved item.`);
                return reject(resp);
            }else{
                console.debug(`Removed item ${JSON.stringify(change.data.id)} from database.`);
                return resolve(resp);
            }
    
        }catch(e){
            console.error(`Unable to remove item ${change.data.id} from database because ${e}.`)
            return reject(e);
        }
    })
}



export const Save = async ({getSessionStorage,addToSessionStorage,deleteSessionStorage}) => {
       let changes = JSON.parse(sessionStorage.getItem('changes'));
        //TODO: Add a way to save files, this will be done similar to image but in the file folder structure

       let imageChanges = changes.filter(x=>['image','document'].includes(x.type.toLowerCase()));

        let textChanges = changes.filter(x=>x.type.toLowerCase()==='text');    
        let ordered_changes = [...imageChanges,...textChanges];
    
        const promise_for_images = (change) =>{
           return new Promise ((resolve,reject) => {
            if((change.type.toLowerCase() === 'image')||(change.type.toLowerCase() === 'document')){
                switch(change.action.toLowerCase()){
                    case 'create':
                    case 'modify':
                        return saveImage(change)
                            .then(url=>{
                                //find the section change object for this image and update the image field with the new url
                                let section = textChanges.find(item =>((item.type == "text") && (item.data.id == change.location.object_id)));
                                
                                if(section){
                                    if(section.data.image == change.data.blob){
                                        textChanges[textChanges.indexOf(section)].data.image = url;
                                    }
                                    else{

                                        let eventDocument = section.data.documents?.find(item=>item.content == change.data.blob);
                                        if(eventDocument){
                                            if(eventDocument.content == change.data.blob){
                                                let indexOfSection = textChanges.indexOf(section)
                                                let documentList = textChanges[indexOfSection].data.documents;
                                                documentList[documentList.indexOf(eventDocument)].content = url;
                                                textChanges[indexOfSection].data.documents = documentList;
                                            }
                                        }
                                        let contactImage = section.data.contact.find(item=>item.image == change.data.blob);
                                        if(contactImage){
                                            let indexOfSection = textChanges.indexOf(section);
                                            let contactlist = textChanges[indexOfSection].data.contact;
                                            contactlist[contactlist.indexOf(contactImage)].image = url;
                                            textChanges[indexOfSection].data.contact = contactlist;
                        
                                        }
                                        
                                    }
                                   
                                }
                                imageChanges[imageChanges.indexOf(change)].data.url = url;
                                
                                removeFromSessionStorage(change);
                                //Add collection name to localStorage to know that it needs to be published.
                                let item = 
                                {
                                    // category: change.type,
                                    collection:  change.location.collection.split("/").join("")
                                    // folder: change.location.collection.split("/").join("") + "/"+change.type, //collection name
                                    // name: change.data.name,
                                    // change:change
                                }
                                    // addToLocalStorage(item);

                                    return resolve(change);
                            }).catch(e=>{
                                console.error('Unable to update section with saved image: ',e);
                                 return reject(e);
                            });
                            
                        
                
                    case 'delete':
                       return deleteImage(change).then(()=>{
                            //Add collection name to localStorage to know that it needs to be published.
                            let item = 
                            {
                                collection:  change.location.collection.split("/").join("")
                                // category: change.type,
                                // folder: change.location.collection.split("/").join("") + "/"+change.type, //collection name
                                // name: change.data.name,
                                // change:change
                            }
                            // addToLocalStorage(item);
                            removeFromSessionStorage(change);
                            return resolve(change);
                            // change;
                            // saveResults.push("accumulator value");
                        }).catch((e)=>{
                            console.error("Caught an error trying to call deleteImage ",e);
                            return reject(e);
                            // saveResults.push('error');
                        });
                 
                    default:
                        return;
                }
            }
           
            });
        }

        const promise_for_texts = (change)=>{
            return new Promise ((resolve,reject) =>{
                if(change.type.toLowerCase() === 'text'){
                    switch(change.action.toLowerCase()){
                        case 'create':
                        case 'modify':
                            return saveText(change).then((resp)=>{
                                //Add collection name to localStorage to know that it needs to be published.
                                let item = 
                                {
                                    // category: 'text',
                                    collection:change.location.collection.split("/").join("")
                                    // name: change.location.collection, //collection name
                                    // change:change
                                }
                                // addToLocalStorage(item);
                                removeFromSessionStorage(change);
                                return resolve(change);
                            }).catch((e)=>   {
                                return reject(e)
                            });
               
                        case 'delete':
                            return deleteSection(change).then(()=>{
                                //if a section was deleted and had an image, remove image from storage
                                //TODO:
                                let item = 
                                {
                                    // category: 'text',
                                    collection:change.location.collection.split("/").join("")
                                    // name: change.location.collection, //collection name
                                    // change:change
                                }
                                // addToLocalStorage(item);
                                removeFromSessionStorage(change);
                                return resolve(change);
                            }).catch((e)=>   {
                                return reject(e);
                            });
                    
                        default:
                            return;
                    }
                }
            })
        }

        return Promise.allSettled(
            imageChanges.map((change)=>{
                return promise_for_images(change);       
            })
            ).then((results)=>{
                if(results.some((result)=>result.status=='rejected')){
                    // Some of the results did not save, properly.
                    let failures = results.filter((result)=>(result.status=='rejected'));
                    handlesUnsavedChanges(getSessionStorage);
                    return {status:400,message:failures};
                }else{
                     //Results all saved successfully
                    console.debug('Saved successfully: ',results);
                    return Promise.allSettled(
                        textChanges.map((change)=>{
                            return promise_for_texts(change);
                        })
                    ).then((text_results)=>{
                        if(text_results.some((result)=>result.status=='rejected')){
                            // Some of the results did not save, properly.
                            let failures = text_results.filter((result)=>(result.status=='rejected'));
                            handlesUnsavedChanges(getSessionStorage);
                            return {status:400,message:failures};
                        }else{
                            let all_results = [...results,...text_results];
                            
                            return {status:200,data:all_results};
                        }
                    })
                        
                   
                }
        });
        
}
               

//Add unsaved changes to db for error handling and debugging
const handlesUnsavedChanges = async (getSessionStorage)=>{
    // let sessionStore = await getSessionStorage();
    let sessionStore = JSON.parse(sessionStorage.getItem('changes'))?JSON.parse(sessionStorage.getItem('changes')):[];

    let resp = await create("admin/error",sessionStore);
    if(!resp.error){
        console.debug('Finished POSTing Errors to DB: ',resp);
        return resp;
     }else{
        //Rejected promises come here
        console.debug('Error POSTing Errors to DB: ',resp);
        return resp;
     }
}

//Remove an item from session storage
const removeFromSessionStorage = async (change) =>{
   console.debug('removing ',change);
    // let sessionStore = await getSessionStorage();
    // sessionStore = sessionStore?sessionStore:[];
    let sessionStore = JSON.parse(sessionStorage.getItem('changes'))?JSON.parse(sessionStorage.getItem('changes')):[];
    if(sessionStore.length > 0){
        if((change.type == 'image')||(change.type == 'document')){
            let removeThis = sessionStore.find((item)=>item.location.object_id == change.location.object_id);
            sessionStore.splice(sessionStore.indexOf(removeThis),1);
        }else if(change.type == 'text'){
            let removeThis = sessionStore.find((item)=>item.data.id == change.data.id);
            sessionStore.splice(sessionStore.indexOf(removeThis),1);
        }
        if(sessionStore.length<=0){
            // removeFromSessionStorage();
            //TODO: replace with remove item function
            sessionStorage.removeItem('changes');
        }else{
            // addToSessionStorage(sessionStore);
            sessionStorage.setItem('changes',JSON.stringify(sessionStore));
        }
        
    }
}

/**** PUBLISH CHANGES HANDLERS  ****/
const publishText = (change) =>{
    return new Promise (async(resolve,reject)=>{
        try{
            //Save objects in Firestore
            const {CreatedDate,LastModifiedDate, ...body } = Object.assign({}, change.data);
            //Add or update object fields in firestore
             let resp = await create(change.location.collection,body);
             if(!resp.error){
                return resolve(resp);
             }else{
                //Rejected promises come here
                return reject(resp);
             }
        }catch(err){
            return reject(err);
        }
    })
}

const publishDeleteSection = (change) =>{
    return new Promise ( async (resolve,reject)=>{
        try{
            let resp = await deleteOne("web/"+change.location.collection,change.data.id);

            if(resp.error){
                console.debug(`Unable to remove item ${JSON.stringify(change.data.id)} from database. It must not be a saved item.`);
                return reject(resp);
            }else{
                console.debug(`Removed item ${JSON.stringify(change.data.id)} from database.`);
                return resolve(resp);
            }
    
        }catch(e){
            console.error(`Unable to remove item ${change.data.id} from database because ${e}.`)
            return reject(e);
        }
    })
}


const removeFromLocalStorage = async (change) =>{
   
    // let sessionStore = await getSessionStorage();
    // sessionStore = sessionStore?sessionStore:[];
    let localStore = JSON.parse(localStorage.getItem('changes'))?JSON.parse(localStorage.getItem('changes')):[];

    if(localStore.length > 0){
        if((change.change.type == 'image')||(change.change.type == 'document')){
            let removeThis = localStore.find((item)=>item.change.location.object_id == change.change.location.object_id);
            localStore.splice(localStore.indexOf(removeThis),1);
        }else if(change.change.type == 'text'){
            let removeThis = localStore.find((item)=>item.change.data.id == change.change.data.id);
            localStore.splice(localStore.indexOf(removeThis),1);
        }
        if(localStore.length<=0){
            // removeFromSessionStorage();
            //TODO: replace with remove item function
            localStorage.removeItem('changes');
        }else{
            // addToSessionStorage(sessionStore);
            localStorage.setItem('changes',JSON.stringify(localStore));
        }
        
    }
}

const addToLocalStorage = (item) =>{
    // let newItem = Object.assign(item,...{id:'changes'});
    // create('localStorage',newItem);
    
    // let localStore = JSON.parse(localStorage.getItem('publish'))?JSON.parse(localStorage.getItem('publish')):[];
    
    // switch(item.category){
    //     case 'text':
    //         let exists = localStore.includes(element => ((element.name == item.name) && (element.category == item.category)));
    //         if(!exists){
    //             localStore.push(item);
    //             localStorage.setItem('changes',JSON.stringify(localStore));
    //         }
    //         break;
    //     case 'image':
    //     case 'document':
    //         localStore.push(item);
    //         localStorage.setItem('changes',JSON.stringify(localStore));
    //         break;
       
    //     default:
    //         break;
    // }
}

//Copies a file from one storage location to another
export const copyFile = (filename,source,path,destination) =>{
    return new Promise( async (resolve,reject)=> {
        
    /*
    ex. let filename = "aboutwhoHero.jpeg";
        let source = "admin_development";
        let destination = 'images_development';
        */
    try{
        let resp = await copyImage(filename,source,path,destination);
        if(!resp.error){
            console.debug(`Finished copying ${filename} from ${source} to ${destination}`);
            let url = await getFile(`${destination}`,`${path}/`,filename);
            if(url){
                console.debug(`Finished copying ${filename} from ${source} to ${destination} new url ${url.data.url}`);
                return resolve(url.data.url);
            }
           
        }else{
            console.error(`Error copying ${filename} from ${source} to ${destination}: ${resp.error} `);
            return reject(resp.error);
        }
       
    }catch(err){
        console.error(`Error copying ${filename} from ${source} to ${destination}: Reason ${err} `);
        return reject(err);
    }
   
    });
    // return fetch(url).then(r=>r.blob()).then((blob)=> blob);
}
const publishdeleteImage = (change) => {
    return new Promise( async(resolve,reject)=>{
        try{
            console.debug('Deleting file ', change.data.name);
            let fileName = change.data.name;
            let resp = await deleteFile('web',`${change.location.collection.split('/').join('')}/${change.type}/`,fileName);
            if(resp){
                console.debug('Delete Response: ',resp);
                return resolve(resp);
            }
        }catch(error){
            console.error('Error deleting file: ',error);
            return reject(error);
        };
    });
}
export const publish = async (e) =>{
   
       cmsCollections.map(collection=>{
        publishCollection(collection.name).then(data=>{
           return {status:200,data:data};
        }).catch((error)=>{
            console.error(error);
            return {status:400,error:error};
        });
       });
      
       
       /*** old publish 
       let imageChanges = changes.filter(x=>((x.category.toLowerCase() === 'image')||(x.category.toLowerCase() === 'document')));

       let textChanges = changes.filter(x=>x.category.toLowerCase()==='text');    
     
       const promise_for_images = (change) =>{
            return new Promise ((resolve,reject) => {
                if((change.category === 'image')||(change.category === 'document')){
                    
                    let filename = change.name;
                    let source = 'admin' ;
                    let destination = 'web';
                    let path = change.folder;
                    switch(change.change.action.toLowerCase()){
                        case 'create':
                        case 'modify':
                            return copyFile(filename,source,path,destination).then(url=>{
                                

                                //find the section change object for this image and update the image field with the new url
                                let section = textChanges.find(item =>((item.change.type == "text") && (item.change.data.id == change.change.location.object_id)));
                                        
                                if(section){
                                    if(change.category == 'image' && !change.name.includes('contact')){
                                        textChanges[textChanges.indexOf(section)].change.data.image = url;
                                    }
                                    else{

                                        let eventDocument = section.change.data.documents.find(item=>item.name == change.change.data.name);
                                        if(eventDocument){
                                            if(eventDocument.content == change.change.data.url){
                                                let indexOfSection = textChanges.indexOf(section)
                                                let documentList = textChanges[indexOfSection].change.data.documents;
                                                documentList[documentList.indexOf(eventDocument)].content = url;
                                                textChanges[indexOfSection].change.data.documents = documentList;
                                            }
                                        }
                                        let contactImage = section.change.data.contact.find(item=>item.image == change.change.data.url);
                                        if(contactImage){
                                            if(contactImage.image == change.change.data.url){
                                                let indexOfSection = textChanges.indexOf(section)
                                                let contactlist = textChanges[indexOfSection].change.data.contact;
                                                contactlist[contactlist.indexOf(contactImage)].image = url;
                                                textChanges[indexOfSection].change.data.contact = contactlist;
                                            }
                                        }
                                        
                                    }
                                    
                                }
                                imageChanges[imageChanges.indexOf(change)].change.data.url = url;
                                

                                removeFromLocalStorage(change);
                                //TODO: remove from Local storage
                                return resolve(change);
                            }).catch(e=>{
                                console.error('Unable to update section with published image: ',e);
                                return reject(e);
                            });   
                        case 'delete':
                            // let change = change.change;
                            return publishdeleteImage(change.change).then(()=>{
                                //Add collection name to localStorage to know that it needs to be published.
                                let item = 
                                {
                                    category: change.change.type,
                                    folder: change.change.location.collection.split("/").join("") + "/"+change.change.type, //collection name
                                    name: change.change.data.name,
                                    change:change
                                }
                                removeFromLocalStorage(change);
                               
                                return resolve(change.change);
                                // change;
                                // saveResults.push("accumulator value");
                            }).catch((e)=>{
                                console.error("Caught an error trying to call deleteImage ",e);
                                return reject(e);
                                // saveResults.push('error');
                            });
                    
                     default:
                         return;        
                    }
                }

            })
        }

        const promise_for_texts = (change)=>{
            return new Promise ((resolve,reject) =>{
                if(change.category === 'text'){   
                
                    console.debug('component to be published: ',change.name);
                    switch(change.change.action.toLowerCase()){
                        case 'create':
                        case 'modify':
                            return publishText(change.change).then((resp)=>{
                                console.debug('Published Collection : ' + change.name + " " + resp);
                                removeFromLocalStorage(change);                 
                                return resolve(change);
                            }).catch((e)=>   {
                                return reject(e)
                            });
               
                        case 'delete':
                            return publishDeleteSection(change.change).then(()=>{

                                removeFromLocalStorage(change);       
                                return resolve(change);
                            }).catch((e)=>   {
                                return reject(e);
                            });
                    
                        default:
                            return;
                    }
                }
            })
        }


       //Get all the changes from admin database
       
       console.debug('Publishing changes.. ',changes);    
       return Promise.allSettled(
            imageChanges.map((change) => {
                return promise_for_images(change);
            })
       ).then((results)=>{
            console.debug('results: ',typeof(results));
            if(results.some((result)=>result.status=='rejected')){
                // Some of the results did not save, properly.
                console.debug('Results not saved fully: ',results);
                let failures = results.filter((result)=>(result.status=='rejected'));
               
                return {status:400,message:failures};
            }else{
                //Results all saved successfully
                console.debug('Saved successfully: ',results);
                return Promise.allSettled(
                    textChanges.map((change)=>{
                        return promise_for_texts(change);
                    })
                ).then((text_results)=>{
                    if(text_results.some((result)=>result.status=='rejected')){
                        // Some of the results did not save, properly.
                        console.debug('Successfully published changes: ',text_results);
                        let failures = text_results.filter((result)=>(result.status=='rejected'));
                   
                        return {status:400,message:failures};
                    }else{
                        let all_results = [...results,...text_results];
                        localStorage.removeItem('changes');
                        return {status:200,data:all_results};
                    }
                })
            }
       });
       */
}


